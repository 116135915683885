import { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { isMobile, isDesktop } from "react-device-detect";

import TitlePanel from "./components/TitlePanel";
import TutorialButton from "./components/TutorialButton";
import NetworkComponent from "./components/NetworkComponent";
import Explanation from "./Explanation";

import "./App.css";

const onMobile = !isDesktop || window.innerWidth < 800;

function App() {
  const [tutorialOpen, setTutorialOpen] = useState(false);
  const [tutorialIndex, setTutorialIndex] = useState(0);

  const { t } = useTranslation();

  const onTutorialClick = (index, open) => {
    setTutorialIndex(index);
    if (open === undefined) {
      return;
    }
    setTutorialOpen(open);
  };

  const openOnDesktop = (index) => {
    if (onMobile) {
      return false;
    }
    return tutorialOpen && tutorialIndex === index;
  };

  const onSlideChange = (back, close) => {
    if (back) {
      setTutorialIndex(tutorialIndex - 1);
    } else {
      setTutorialIndex(tutorialIndex + 1);
    }
    if (close) {
      setTutorialIndex(0);
      setTutorialOpen(false);
    }
  };

  return (
    <div className="App">
      <TitlePanel />

      <Grid container>
        {isMobile ? (
          <Grid item className="grid-item section" xs={12}>
            <Typography variant="h5" style={{ color: "#666" }}>
              {t("mobileNote")}
            </Typography>
          </Grid>
        ) : null}
        {isMobile ? (
          <Grid item className="grid-item section section-0" xs={12}>
            <Typography variant="h5" gutterBottom style={{ color: "#666" }}>
              {t("subtitle")}
            </Typography>
          </Grid>
        ) : null}
        <Grid item className="grid-item section section-1" xs={12}>
          <NetworkComponent
            tutorialOpen={tutorialOpen}
            tutorialIndex={tutorialIndex}
            onTutorialClick={onTutorialClick}
            openOnDesktop={openOnDesktop}
          />
        </Grid>
        <Grid item className="grid-item section section-2" xs={12}>
          <Explanation
            tutorialOpen={tutorialOpen}
            tutorialIndex={tutorialIndex}
            openOnDesktop={openOnDesktop}
            onSlideChange={onSlideChange}
          />
        </Grid>
      </Grid>
      <TutorialButton
        onClick={() => {
          setTutorialOpen(!tutorialOpen);
        }}
      />
    </div>
  );
}

export default App;
