import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { useTranslation } from "react-i18next";

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    padding: "4px",
    background: "#fff",
    border: "1px solid #999",
    borderRadius: "4px",
    fontSize: "1em",
    color: "rgba(0, 0, 0, 0.87)",
    width: "20vw",
  },
}))(Tooltip);

const LegendTooltip = ({ children, title, titleParams }) => {
  const { i18n } = useTranslation();
  return (
    <HtmlTooltip title={i18n.t(title, titleParams)} placement="left">
      {children}
    </HtmlTooltip>
  );
};

export default LegendTooltip;
