export const goalInfoEN = {
  1: "End poverty in all its forms everywhere",
  2: "End hunger, achieve food security and improved nutrition and promote sustainable agriculture",
  3: "Ensure healthy lives and promote well-being for all at all ages",
  4: "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all",
  5: "Achieve gender equality and empower all women and girls",
  6: "Ensure access to water and sanitation for all",
  7: "Ensure access to affordable, reliable, sustainable and modern energy",
  8: "Promote inclusive and sustainable economic growth, employment and decent work for all",
  9: "Build resilient infrastructure, promote sustainable industrialization and foster innovation",
  10: "Reduce inequality within and among countries",
  11: "Make cities inclusive, safe, resilient and sustainable",
  12: "Ensure sustainable consumption and production patterns",
  13: "Take urgent action to combat climate change and its impacts",
  14: "Conserve and sustainably use the oceans, seas and marine resources",
  15: "Sustainably manage forests, combat desertification, halt and reverse land degradation, halt biodiversity loss",
  16: "Promote just, peaceful and inclusive societies",
  17: "Revitalize the global partnership for sustainable development",
};

export const goalInfoES = {
  1: "Poner fin a la pobreza en todas sus formas en todo el mundo",
  2: "Poner fin al hambre, lograr la seguridad alimentaria y la mejora de la nutrición y promover la agricultura sostenible",
  3: "Garantizar una vida sana y promover el bienestar para todos en todas las edades",
  4: "Garantizar una educación inclusiva, equitativa y de calidad y promover oportunidades de aprendizaje durante toda la vida para todos",
  5: "Lograr la igualdad entre los géneros y empoderar a todas las mujeres y las niñas",
  6: "Garantizar la disponibilidad de agua y su gestión sostenible y el saneamiento para todos",
  7: "Garantizar el acceso a una energía asequible, segura, sostenible y moderna",
  8: "Promover el crecimiento económico inclusivo y sostenible, el empleo y el trabajo decente para todos",
  9: "Construir infraestructuras resilientes, promover la industrialización sostenible y fomentar la innovación",
  10: "Reducir la desigualdad en y entre los países",
  11: "Lograr que las ciudades sean más inclusivas, seguras, resilientes y sostenibles",
  12: "Garantizar modalidades de consumo y producción sostenibles",
  13: "Adoptar medidas urgentes para combatir el cambio climático y sus efectos",
  14: "Conservar y utilizar sosteniblemente los océanos, los mares y los recursos marinos",
  15: "Gestionar sosteniblemente los bosques, luchar contra la desertificación, detener e invertir la degradación de las tierras, detener la pérdida de biodiversidad",
  16: "Promover sociedades justas, pacíficas e inclusivas",
  17: "Revitalizar la Alianza Mundial para el Desarrollo Sostenible",
};