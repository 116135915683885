import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { withStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { Typography } from "@material-ui/core";
import { useTranslation, Trans } from "react-i18next";

import Team from "./components/Team";
import Data from "./components/Data";
import TutorialTooltip from "./components/TutorialTooltip";
import { onDataLinkClick } from "./utilities";

import targetsFile from "./assets/data/Targets_plan_qus_recreo_comparison.zip";

import "./Explanation.css";

const TheTabs = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main
  }
}))(Tabs);

function Explanation(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const { t } = useTranslation();
  return (
    <TutorialTooltip
      title="tutorialSummaryTitle"
      description="tutorialSummaryDescription"
      placement="top"
      open={props.openOnDesktop(10)}
      index={10}
      onSlideChange={props.onSlideChange}
      lastSlide
    >
      <div>
        <TheTabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          variant="fullWidth"
          aria-label="full width tabs example"
          centered
        >
          <Tab
            id="backgroundTab"
            label={<h3 id="Background">{t("background")}</h3>}
          />
          <Tab label={<h3 id="Usage">{t("usage")}</h3>} />
          <Tab label={<h3 id="Data">{t("data")}</h3>} />
          <Tab label={<h3 id="Team">{t("team")}</h3>} />
        </TheTabs>
        <SwipeableViews
          axis={"x"}
          index={value}
          onChangeIndex={handleChangeIndex}
          animateHeight
        >
          {/* First tab */}
          <div className="tab-panel" value={value} index={0}>
            <p>
              <Typography variant="h6" align="center">
                {t("background1Heading")}
              </Typography>
              <Typography variant="body2" align="justify">
                <Trans i18nKey="background1">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://sdgs.un.org/goals"
                  >
                    (SDGs)
                  </a>
                  .
                </Trans>
              </Typography>
            </p>
            <p>
              <Typography variant="h6" align="center">
                {t("background2Heading")}
              </Typography>
              <Typography variant="body2" align="justify">
                {t("background2")}
              </Typography>
            </p>
            <p>
              <Typography variant="h6" align="center">
                {t("background3Heading")}
              </Typography>
              <Typography variant="body2" align="justify">
                {t("background3")}
              </Typography>
            </p>
            <p>
              <Typography variant="h6" align="center">
                {t("background4Heading")}
              </Typography>
              <Typography variant="body2" align="justify">
                {t("background4")}
              </Typography>
            </p>
            <p>
              <Typography variant="h6" align="center">
                {t("background5Heading")}
              </Typography>
              <Typography variant="body2" align="justify">
                {t("background5")}
              </Typography>
            </p>
            <p>
              <Typography variant="h6" align="center">
                {t("background6Heading")}
              </Typography>
              <Typography variant="body2" align="justify">
                <Trans i18nKey="background6">
                  Strong text
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.co-galapagos.org"
                  >
                    www.co-galapagos.org
                  </a>
                </Trans>
              </Typography>
            </p>
          </div>
          {/* Second tab */}
          <div className="tab-panel" value={value} index={1}>
            <p>
              <Typography variant="h6" align="center">
                {t("usage1Heading")}
              </Typography>
              <Typography variant="body2" align="justify">
                {t("usage1")}
              </Typography>
            </p>
            <Typography variant="body2" align="justify">
              <Trans i18nKey="usage2">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://sdgs.un.org/goals"
                >
                  www.co-galapagos.org
                </a>
              </Trans>
            </Typography>
            <p>
              <Typography variant="h6" align="center">
                {t("usage3Heading")}
              </Typography>
              <Typography variant="body2" align="justify">
                {t("usage3")}
              </Typography>
            </p>
            <p>
              <Typography variant="body2" align="justify">
                {t("usage4")}
              </Typography>
            </p>
            <p>
              <Typography variant="h6" align="center">
                {t("usage5Heading")}
              </Typography>
              <Typography variant="body2" align="justify">
                <Trans i18nKey="usage5">
                  <button
                    className="link"
                    onClick={() =>
                      onDataLinkClick(
                        targetsFile,
                        "Targets_plan_qus_recreo_comparison.zip"
                      )
                    }
                  >
                    here
                  </button>
                </Trans>
              </Typography>
            </p>
          </div>
          {/* Third tab */}
          <div className="tab-panel" value={value} index={2}>
            <Data />
          </div>
          {/* Fourth tab */}
          <div className="tab-panel" value={value} index={3}>
            <Team />
          </div>
        </SwipeableViews>
      </div>
    </TutorialTooltip>
  );
}

export default Explanation;
