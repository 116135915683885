import React from "react";
import Grid from "@material-ui/core/Grid";
import { useTranslation } from "react-i18next";

import matrixEN from "../assets/icons/data/matrix_EN.png";
import targetsEN from "../assets/icons/data/targets_EN.png";
import matrixES from "../assets/icons/data/matrix_ES.png";
import targetsES from "../assets/icons/data/targets_ES.png";

import targetsFile from "../assets/data/Galapagos 40 ODS Prioridades EN-ES.pdf";
import matrixFile from "../assets/data/Link information.xlsx";

import { onDataLinkClick } from "../utilities";

const DataLink = ({ src, onClick }) => (
  <Grid item xs={12} sm={6}>
    <img
      className={onClick ? "clickable" : null}
      style={{ width: "100%", maxWidth: "200px" }}
      src={src}
      alt=""
      onClick={onClick ? () => onClick() : null}
    />
  </Grid>
);

function Data() {
  const { i18n } = useTranslation();

  return (
    <div style={{paddingTop: 12}}>
      <Grid
        container
        direction="row"
        justifycontent="space-evenly"
        alignItems="flex-end"
        spacing={2}
      >
        <DataLink
          src={i18n.language !== "es" ? targetsEN : targetsES}
          onClick={() => onDataLinkClick(targetsFile, "targets.pdf")}
        />
        <DataLink
          src={i18n.language !== "es" ? matrixEN : matrixES}
          onClick={() => onDataLinkClick(matrixFile, "matrix.xlsx")}
        />
      </Grid>
    </div>
  );
}

export default Data;
