import * as d3 from "d3";

// The standard convention of setting D3 margins
const MARGIN = { TOP: 0, BOTTOM: 0, LEFT: 0, RIGHT: 0 };
const WIDTH = 30 - MARGIN.LEFT - MARGIN.RIGHT;
const HEIGHT = 20 - MARGIN.TOP - MARGIN.BOTTOM;

class Arrow {
  constructor(element, properties) {
    // Create a reference to this class instance
    let vis = this;
    vis.props = properties;

    // Create the SVG element
    vis.svg = d3
      .select(element)
      .append("svg")
      .attr("width", WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
      .attr("height", HEIGHT + MARGIN.TOP + MARGIN.BOTTOM);

    vis.g = vis.svg
      .append("g")
      .attr("transform", `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`);

    vis.props.data = vis.props.rightArrow
      ? [{ source: 0, target: 30 }]
      : [{ source: 30, target: 0 }];

    var defs = vis.svg.append("svg:defs");

    defs
      .append("svg:marker")
      .attr("id", "grey-marker")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 10)
      .attr("refY", 0)
      .attr("markerWidth", 9)
      .attr("markerHeight", 9)
      .attr("orient", "auto")
      .attr("markerUnits", "userSpaceOnUse")
      .append("svg:path")
      .attr("d", "M0,-5L10,0L0,5")
      .style("fill", "lightgray");

    defs
      .append("svg:marker")
      .attr("id", "colored-marker")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 10)
      .attr("refY", 0)
      .attr("markerWidth", 9)
      .attr("markerHeight", 9)
      .attr("orient", "auto")
      .attr("markerUnits", "userSpaceOnUse")
      .append("svg:path")
      .attr("d", "M0,-5L10,0L0,5")
      .style("fill", vis.props.linkColor);

    vis.update();
  }

  update(p) {
    // Create a reference to this class instance
    let vis = this;
    vis.props = { ...vis.props, ...p };

    // Bind D3 data
    const arrow = vis.g.selectAll(".link").data(vis.props.data);

    // Enter new D3 elements
    arrow
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("stroke-width", 2)
      .style("fill", "none")
      .attr("d", function (d) {
        return `M${d.source},10 L${d.target},10`;
      });

    // Update existing D3 elements
    arrow
      .attr(
        "marker-end",
        vis.props.clickable ? "url(#colored-marker)" : "url(#grey-marker)"
      )
      .attr("stroke", vis.props.clickable ? vis.props.linkColor : "lightgray");

    // Remove old D3 elements
    arrow.exit().remove();
  }
}

export default Arrow;
