export const nodeInfo = {
  1.4: {
    nodeTitleES:
      "Garantizar que todos los hombres y todas las mujeres, en particular los pobres y los vulnerables, tengan los mismos derechos a los recursos económicos y acceso a los servicios básicos, la propiedad y el control de la tierra y otros bienes, la herencia, los recursos naturales, tecnologías apropiadas y los servicios financieros, incluida la microfinanciación",
    nodeTitleEN:
      "Ensure that all men and women, particularly the poor and the vulnerable, have equal rights to economic resources and access to basic services, ownership and control over land and other property, inheritance, natural resources, appropriate technologies and financial services, including microfinance"
  },
  2.2: {
    nodeTitleES:
      "Poner fin a todas las formas de malnutrición, incluso logrando, las metas convenidas internacionalmente sobre el retraso del crecimiento y la desnutrición de los niños/las niñas menores de 5 años, y abordar las necesidades de nutrición de las adolescentes, las mujeres embarazadas, lactantes, adultos mayores y personas con discapacidad",
    nodeTitleEN:
      "Eliminate all forms of malnutrition, including achieving the internationally agreed targets on stunting and undernutrition in children under 5 years of age, and address the nutritional needs of adolescents, pregnant and lactating women, older persons and those with disabilities"
  },
  2.3: {
    nodeTitleES:
      "Fomentar de manera sostenible la productividad agrícola y pesquera y los ingresos de los productores de alimentos a pequeña escala, en particular los agricultores familiares, los ganaderos y los pescadores, entre otras cosas mediante un acceso seguro y equitativo a las tierras, a otros recursos e insumos de producción y a los conocimientos, los servicios financieros, los mercados y las oportunidades para añadir valor y obtener empleos no agrícolas",
    nodeTitleEN:
      "Sustainably boost agricultural and fishing productivity and incomes of small-scale food producers, in particular family farmers, pastoralists and fishers, including through secure and equal access to land, other productive resources and inputs, knowledge, financial services, markets and opportunities for value addition and non-farm employment"
  },
  2.4: {
    nodeTitleES:
      "Asegurar la sostenibilidad de los sistemas de producción de alimentos y aplicar prácticas agrícolas resilientes que aumenten la productividad y la producción, contribuyan al mantenimiento de los ecosistemas, fortalezcan la capacidad de adaptación al cambio climático, los fenómenos meteorológicos extremos, las sequías, las inundaciones y otros desastres, y mejoren progresivamente la calidad de la tierra y el suelo",
    nodeTitleEN:
      "Ensure sustainable food production systems and implement resilient agricultural practices that increase productivity and production, contribute to the maintenance of ecosystems, strengthen resilience to climate change, extreme weather, drought, flooding and other disasters, and progressively improve the quality of land and soil"
  },
  "2.a": {
    nodeTitleES:
      "Aumentar, las inversiones en infraestructura rural, investigación y servicios de extensión agrícola, desarrollo tecnológico y bancos de genes de plantas y ganado a fin de mejorar la capacidad de producción agropecuaria",
    nodeTitleEN:
      "Increase investment in rural infrastructure, agricultural research and extension services, technology development and plant and livestock gene banks in order to enhance agricultural productive capacity"
  },
  3.5: {
    nodeTitleES:
      "Fortalecer la prevención y el tratamiento del abuso de sustancias adictivas, incluido el uso indebido de estupefacientes y el consumo nocivo de alcohol",
    nodeTitleEN:
      "Strengthen the prevention and treatment of substance abuse, including drug abuse and harmful use of alcohol"
  },
  3.7: {
    nodeTitleES:
      "Garantizar el acceso universal a los servicios de salud sexual y reproductiva, incluidos los de planificación familiar, información y educación, y la integración de la salud reproductiva en las estrategias y los programas nacionales y contextualizados a Galápagos",
    nodeTitleEN:
      "Ensure universal access to sexual and reproductive health services, including family planning, information and education, and the integration of reproductive health into national, and Galápagos contextualized, strategies and programmes"
  },
  3.8: {
    nodeTitleES:
      "Lograr la cobertura sanitaria universal, incluida la protección contra los riesgos financieros, el acceso a servicios de salud esenciales de calidad y el acceso a medicamentos y vacunas inocuos, eficaces, asequibles y de calidad para todos/todas",
    nodeTitleEN:
      "Achieve universal health coverage, including financial risk protection, access to quality essential health-care services and access to safe, effective, quality and affordable medicines and vaccines for all"
  },
  "3.c": {
    nodeTitleES:
      "Aumentar la asignación de recursos para infraestructura en salud, la contratación, perfeccionamiento y la capacitación continua y la permanencia de las plazas de personal de salud",
    nodeTitleEN:
      "Increase the allocation of resources for health infrastructure, and the recruitment, continuing development and training, and permanence of health personnel positions"
  },
  4.1: {
    nodeTitleES:
      "Asegurar que todas las niñas y todos los niños terminen la enseñanza primaria y secundaria, que sea gratuita, equitativa, de calidad, calidez, y contextualizada a Galápagos, y producir resultados de aprendizaje pertinentes y efectivos",
    nodeTitleEN:
      "Ensure that all girls and boys complete primary and secondary education, which is free and equitable, of quality and warmth, contextualized to Galápagos, and produces relevant and effective learning outcomes"
  },
  4.2: {
    nodeTitleES:
      "Asegurar que todas las niñas y todos los niños tengan acceso a servicios de atención y desarrollo en la primera infancia y educación preescolar de calidad y calidez, a fin de que estén preparados para la enseñanza primaria",
    nodeTitleEN:
      "Ensure that all girls and boys have access to early childhood development, care and preprimary education, that is of quality and warmth, so that they are ready for primary education"
  },
  4.3: {
    nodeTitleES:
      "Asegurar el acceso igualitario de todos los hombres y todas las mujeres a una formación técnica, profesional y superior de calidad y asequible",
    nodeTitleEN:
      "Ensure equal access for all women and men to quality and affordable technical, vocational and higher education"
  },
  4.4: {
    nodeTitleES:
      "Aumentar considerablemente el número de jóvenes y adultos residentes que tienen las competencias necesarias en todos los campos académicos, técnicos, tecnológicos y profesionales, para acceder al empleo, trabajo decente y emprendimiento",
    nodeTitleEN:
      "Significantly increase the number of youth and resident adults who have relevant skills in all academic, technical, technological and vocational fields for employment, decent jobs and entrepreneurship"
  },
  4.7: {
    nodeTitleES:
      "Asegurar que todos los estudiantes adquieran los conocimientos teóricos y prácticos necesarios para promover el desarrollo sostenible mediante la educación, contextualizada a Galápagos, para el desarrollo y los estilos de vida sostenible, los derechos humanos, la igualdad de género, la promoción de una cultura de paz y no violencia, la ciudadanía mundial y la valoración de la diversidad cultural y la contribución de la cultura al desarrollo sostenible",
    nodeTitleEN:
      "Ensure that all students acquire the knowledge and skills needed to promote sustainable development through education, contextualized to Galápagos, for sustainable development and lifestyles, human rights, gender equality, promotion of a culture of peace and nonviolence, global citizenship, and appreciation of cultural diversity and the contribution of culture to sustainable development"
  },
  "4.c": {
    nodeTitleES:
      "Aumentar considerablemente la oferta de docentes calificados, incluso mediante la cooperación internacional para la formación y capacitación a docentes locales e implementar el currículo contextualizado de Galápagos",
    nodeTitleEN:
      "Significantly increase the supply of qualified teachers, including through international cooperation for the education and training of local teachers, and implement the Galápagos contextualized curriculum"
  },
  5.2: {
    nodeTitleES:
      "Eliminar todas las formas de violencia de género incluidas la trata y la explotación sexual y otros tipos de explotación",
    nodeTitleEN:
      "Eliminate all forms of gender-based violence including trafficking and sexual and other types of exploitation"
  },
  6.1: {
    nodeTitleES:
      "Lograr el acceso universal y equitativo al agua potable a un precio asequible para todos/todas",
    nodeTitleEN:
      "Achieve universal and equitable access to safe and affordable drinking water for all"
  },
  6.2: {
    nodeTitleES:
      "Lograr el acceso a servicios de saneamiento e higiene adecuados y equitativos para todos/todas",
    nodeTitleEN: "Achieve access to adequate and equitable sanitation and hygiene services for all"
  },
  6.3: {
    nodeTitleES:
      "Mejorar la calidad del agua reduciendo la contaminación, enfatizando la reducción de aguas residuales sin tratar, minimizando la emisión de productos químicos y materiales peligrosos, y aumentando considerablemente el reciclado y la reutilización sin riesgos",
    nodeTitleEN:
      "Improve water quality by reducing pollution, particularly that of raw sewage, minimizing the release of hazardous chemicals and materials, and substantially increasing recycling and safe reuse"
  },
  6.4: {
    nodeTitleES:
      "Aumentar considerablemente el uso eficiente de los recursos hídricos en todos los sectores y asegurar la sostenibilidad de la extracción y el abastecimiento de agua potable",
    nodeTitleEN:
      "Substantially increase the water-use efficiency across all sectors and ensure sustainable withdrawal and supply of drinking water"
  },
  "7.b": {
    nodeTitleES:
      "Ampliar la infraestructura y mejorar la tecnología para prestar servicios energéticos modernos y sostenibles",
    nodeTitleEN:
      "Expand infrastructure and upgrade technology for supplying modern and sustainable energy services"
  },
  8.2: {
    nodeTitleES:
      "Lograr de forma sostenible niveles más elevados de productividad económica mediante la diversificación, la modernización tecnológica, la innovación y la investigación, fomentando el uso preferente de la mano de obra local",
    nodeTitleEN:
      "Sustainably achieve higher levels of economic productivity through diversification, technological upgrading, innovation and research, encouraging the preferential use of local labour"
  },
  8.6: {
    nodeTitleES:
      "Reducir considerablemente la proporción de jóvenes sin empleo y no cursan estudios ni reciben capacitación, y fomentar la creación de oportunidades viables y diversas en el entorno galapagueño",
    nodeTitleEN:
      "Substantially reduce the proportion of youth not in employment, education or training, and encourage the creation of viable opportunities in the Galápagos environment"
  },
  8.9: {
    nodeTitleES:
      "Elaborar y poner en práctica políticas encaminadas a promover un turismo sostenible que cree puestos de trabajo y promueva la cultura y los productos locales",
    nodeTitleEN:
      "Develop and implement policies to promote sustainable tourism that creates jobs and promotes local culture and products"
  },
  9.5: {
    nodeTitleES:
      "Aumentar la investigación científica y mejorar la capacidad tecnológica de los distintos sectores, fomentando la innovación y aumentando considerablemente el número de personas que trabajan en investigación y desarrollo, y la inversión de los sectores público y privado en estas áreas",
    nodeTitleEN:
      "Enhance scientific research and improve the technological capacities of different sectors, encouraging innovation and substantially increasing the number of research and development workers, and public and private sector investment in these areas"
  },
  "9.c": {
    nodeTitleES:
      "Aumentar significativamente el acceso a la tecnología de la información y las comunicaciones y esforzarse por proporcionar acceso universal y asequible a Internet",
    nodeTitleEN:
      "Significantly increase access to information and communications technology and strive to provide universal and affordable access to the Internet"
  },
  11.2: {
    nodeTitleES:
      "Potenciar, promover y garantizar el acceso a sistemas de transporte seguros, asequibles, accesibles y sostenibles para todos/todas y mejorar la seguridad vial, en particular mediante la ampliación del transporte público, prestando especial atención a las necesidades de las personas en situación de vulnerabilidad, las mujeres, los niños/las niñas, las personas con discapacidad y los adultos mayores",
    nodeTitleEN:
      "Enhance, promote and ensure access to safe, affordable, accessible and sustainable transport systems for all and improve road safety, including through the expansion of public transport, with special attention to the needs of those in vulnerable situations, women, children, persons with disabilities and older persons"
  },
  11.3: {
    nodeTitleES:
      "Garantizar la urbanización inclusiva y sostenible y la capacidad para la planificación y la gestión participativa, integrada y sostenible de los asentamientos humanos",
    nodeTitleEN:
      "Ensure inclusive and sustainable urbanization and capacity for participatory, integrated and sustainable human settlement planning and management"
  },
  11.4: {
    nodeTitleES:
      "Redoblar y fomentar los esfuerzos para proteger y salvaguardar el patrimonio cultural y natural de Galápagos",
    nodeTitleEN:
      "Strengthen and promote efforts to protect and safeguard the cultural and natural heritage of Galápagos"
  },
  "11.b": {
    nodeTitleES:
      "Implementar políticas y planes integrados para promover la inclusión, el uso eficiente de los recursos, la mitigación del cambio climático y la adaptación a él y la resiliencia ante los desastres, y desarrollar y poner en práctica, en consonancia con los acuerdos internacionales, la gestión integral de los riesgos de desastre a todos los niveles",
    nodeTitleEN:
      "Implement integrated policies and plans toward inclusion, resource efficiency, mitigation and adaptation to climate change, and resilience to disasters, and develop and implement, in line with international agreements, holistic disaster risk management at all levels"
  },
  12.2: {
    nodeTitleES: "Lograr la gestión sostenible y el uso eficiente de los recursos naturales",
    nodeTitleEN: "Achieve sustainable management and efficient use of natural resources"
  },
  12.5: {
    nodeTitleES:
      "Reducir la generación de desechos mediante actividades de prevención, reducción, reciclado y reutilización",
    nodeTitleEN: "Reduce waste generation through prevention, reduction, recycling and reuse"
  },
  14.1: {
    nodeTitleES:
      "Prevenir y reducir significativamente la contaminación marina de todo tipo, en particular la producida por actividades realizadas en tierra, incluidos los desechos marinos y la polución por nutrientes",
    nodeTitleEN:
      "Prevent and significantly reduce marine pollution of all kinds, in particular from landbased activities, including marine debris and nutrient pollution"
  },
  14.2: {
    nodeTitleES:
      "Gestionar y proteger sosteniblemente los ecosistemas marinos y costeros para evitar efectos adversos importantes, incluso fortaleciendo su resiliencia, y adoptar medidas para restaurarlos a fin de restablecer la salud y la productividad de los océanos",
    nodeTitleEN:
      "Sustainably manage and protect marine and coastal ecosystems to avoid significant adverse impacts, including by strengthening their resilience, and take action for their restoration, in order to achieve healthy and productive oceans"
  },
  14.4: {
    nodeTitleES:
      "Reglamentar eficazmente la explotación pesquera y poner fin a la pesca excesiva, la pesca ilegal, no declarada y no reglamentada y las prácticas pesqueras destructivas, incluyendo en la zona económica exclusiva y aplicar planes de gestión con fundamento científico, a fin de restablecer las poblaciones de peces en el plazo más breve posible, al menos alcanzando niveles que puedan producir el máximo rendimiento sostenible de acuerdo con sus características biológicas",
    nodeTitleEN:
      "Effectively regulate harvesting and end overfishing, illegal, unreported and unregulated fishing and destructive fishing practices, including in the exclusive economic zone, and implement science-based management plans, in order to restore fish stocks in the shortest time feasible, at least to levels that can produce maximum sustainable yield as determined by their biological characteristics"
  },
  15.5: {
    nodeTitleES:
      "Adoptar medidas urgentes y significativas para reducir la degradación de los hábitats naturales, detener la pérdida de biodiversidad y proteger las especies amenazadas y evitar su extinción",
    nodeTitleEN:
      "Take urgent and significant action to reduce the degradation of natural habitats, halt the loss of biodiversity and protect and prevent the extinction of threatened species"
  },
  15.7: {
    nodeTitleES:
      "Adoptar medidas urgentes para poner fin a la caza furtiva y el tráfico de especies protegidas de flora y fauna silvestres",
    nodeTitleEN:
      "Take urgent action to end poaching and trafficking of protected species of wild flora and fauna"
  },
  15.8: {
    nodeTitleES:
      "Fortalecer medidas para prevenir la introducción de especies exóticas invasoras y reducir significativamente sus efectos en los ecosistemas terrestres y acuáticos y controlar o erradicar las especies prioritarias",
    nodeTitleEN:
      "Strengthen measures to prevent the introduction of invasive alien species, and significantly reduce their impact on terrestrial and water ecosystems, and control or eradicate priority species"
  },
  16.2: {
    nodeTitleES:
      "Poner fin al maltrato, la explotación, la trata y todas las formas de violencia contra y tortura de los niños/las niñas",
    nodeTitleEN:
      "End abuse, exploitation, trafficking and all forms of violence against, and torture of, children"
  },
  16.5: {
    nodeTitleES: "Eliminar la corrupción y el soborno en todas sus formas",
    nodeTitleEN: "Eliminate corruption and bribery in all their forms"
  }
};
