import { useState } from "react";
import { useTranslation } from "react-i18next";

import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

import "./TutorialButton.css";

const TutorialButton = ({ onClick }) => {
  const [closed, setClosed] = useState(false);
  const { i18n } = useTranslation();

  if (closed) {
    return null;
  }

  return (
    <div className="tutorial-button-container">
      <Alert icon={false} severity="info" color="transparent" onClose={() => setClosed(true)}>
        <Button color="secondary" variant="contained" size="medium" onClick={onClick}>
          {i18n.t("tutorial")}
        </Button>
      </Alert>
    </div>
  );
};

export default TutorialButton;
