export const linkInfo = {
  1: {
    linkStatusES: "Débil",
    linkStatusEN: "Weak",
    linkColor: "#a4c2f4",
    linkTooltip: "weakTooltip",
  },
  2: {
    linkStatusES: "Medio",
    linkStatusEN: "Medium",
    linkColor: "#3c78d8",
    linkTooltip: "mediumTooltip",
  },
  3: {
    linkStatusES: "Fuerte",
    linkStatusEN: "Strong",
    linkColor: "#1c4587",
    linkTooltip: "strongTooltip",
  },
};
