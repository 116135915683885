import React, { Component, useState, useEffect, useRef } from "react";
import Grid from "@material-ui/core/Grid";
import IconDownload from "@material-ui/icons/CloudDownload";
import * as d3 from "d3";
import { isDesktop } from "react-device-detect";
import { useTheme } from "@material-ui/core/styles";
import classNames from "classnames";
import { useTranslation, withTranslation } from "react-i18next";
import Papa from "papaparse";
import html2canvas from "html2canvas";

import NetworkWrapper from "./NetworkWrapper";
import Arrow from "./Arrow";
import TutorialTooltip from "./TutorialTooltip";
import LegendTooltip from "./LegendTooltip";
import { CustomButtonBlue } from "./CustomButtons";

import { data } from "../data/SDG";
import { goalInfoEN, goalInfoES } from "../data/goalInfo";
import { nodeInfo } from "../data/nodeInfo";
import { linkInfo } from "../data/linkInfo";
import { indicatorInfo } from "../data/indicatorInfo";

import goal1EN from "../assets/icons/sdg/EN/E-WEB-Goal-01.png";
import goal2EN from "../assets/icons/sdg/EN/E-WEB-Goal-02.png";
import goal3EN from "../assets/icons/sdg/EN/E-WEB-Goal-03.png";
import goal4EN from "../assets/icons/sdg/EN/E-WEB-Goal-04.png";
import goal5EN from "../assets/icons/sdg/EN/E-WEB-Goal-05.png";
import goal6EN from "../assets/icons/sdg/EN/E-WEB-Goal-06.png";
import goal7EN from "../assets/icons/sdg/EN/E-WEB-Goal-07.png";
import goal8EN from "../assets/icons/sdg/EN/E-WEB-Goal-08.png";
import goal9EN from "../assets/icons/sdg/EN/E-WEB-Goal-09.png";
import goal11EN from "../assets/icons/sdg/EN/E-WEB-Goal-11.png";
import goal12EN from "../assets/icons/sdg/EN/E-WEB-Goal-12.png";
import goal14EN from "../assets/icons/sdg/EN/E-WEB-Goal-14.png";
import goal15EN from "../assets/icons/sdg/EN/E-WEB-Goal-15.png";
import goal16EN from "../assets/icons/sdg/EN/E-WEB-Goal-16.png";

import goal1ES from "../assets/icons/sdg/ES/S-WEB-Goal-01.png";
import goal2ES from "../assets/icons/sdg/ES/S-WEB-Goal-02.png";
import goal3ES from "../assets/icons/sdg/ES/S-WEB-Goal-03.png";
import goal4ES from "../assets/icons/sdg/ES/S-WEB-Goal-04.png";
import goal5ES from "../assets/icons/sdg/ES/S-WEB-Goal-05.png";
import goal6ES from "../assets/icons/sdg/ES/S-WEB-Goal-06.png";
import goal7ES from "../assets/icons/sdg/ES/S-WEB-Goal-07.png";
import goal8ES from "../assets/icons/sdg/ES/S-WEB-Goal-08.png";
import goal9ES from "../assets/icons/sdg/ES/S-WEB-Goal-09.png";
import goal11ES from "../assets/icons/sdg/ES/S-WEB-Goal-11.png";
import goal12ES from "../assets/icons/sdg/ES/S-WEB-Goal-12.png";
import goal14ES from "../assets/icons/sdg/ES/S-WEB-Goal-14.png";
import goal15ES from "../assets/icons/sdg/ES/S-WEB-Goal-15.png";
import goal16ES from "../assets/icons/sdg/ES/S-WEB-Goal-16.png";

import linksCSVEN from "../assets/data/Link information EN.csv";
import linksCSVES from "../assets/data/Link information ES.csv";
import { Typography } from "@material-ui/core";

const onMobile = !isDesktop || window.innerWidth < 800;

// Parse local CSV file
let linksData = {};
Papa.parse(linksCSVEN, {
  header: true,
  download: true,
  skipEmptyLines: true,
  complete: function (results) {
    linksData.EN = results;
  }
});
Papa.parse(linksCSVES, {
  header: true,
  download: true,
  skipEmptyLines: true,
  complete: function (results) {
    linksData.ES = results;
  }
});

const sdgColors = {
  1: "#E5243B",
  2: "#DDA63A",
  3: "#4C9F38",
  4: "#C5192D",
  5: "#FF3A21",
  6: "#26BDE2",
  7: "#FCC30B",
  8: "#A21942",
  9: "#FD6925",
  11: "#FD9D24",
  12: "#BF8B2E",
  14: "#0A97D9",
  15: "#56C02B",
  16: "#00689D"
};

const sdgIconsEN = {
  1: goal1EN,
  2: goal2EN,
  3: goal3EN,
  4: goal4EN,
  5: goal5EN,
  6: goal6EN,
  7: goal7EN,
  8: goal8EN,
  9: goal9EN,
  11: goal11EN,
  12: goal12EN,
  14: goal14EN,
  15: goal15EN,
  16: goal16EN
};

const sdgIconsES = {
  1: goal1ES,
  2: goal2ES,
  3: goal3ES,
  4: goal4ES,
  5: goal5ES,
  6: goal6ES,
  7: goal7ES,
  8: goal8ES,
  9: goal9ES,
  11: goal11ES,
  12: goal12ES,
  14: goal14ES,
  15: goal15ES,
  16: goal16ES
};

const legendOpacity = 0.4;

const SDGIcon = ({ src, className, alt }) => <img src={src} className={className} alt={alt} />;

const SDGLegend = ({ selectedGoals, onGoalClicked, mobile, open, iconClassName, onSlideChange }) => {
  const { i18n } = useTranslation();
  return (
    <TutorialTooltip
      title="tutorialGoalsTitle"
      description="tutorialGoalsDescription"
      placement="right"
      open={open}
      index={1}
      onSlideChange={onSlideChange}
    >
      <Grid
        container
        direction={"row"}
        justifyContent="center"
        style={{
          height: "100%",
          alignItems: "center",
        }}
      >
        {Object.keys(sdgColors).map((v) => (
          <LegendTooltip
            key={v}
            title="goalTooltip"
            titleParams={{
              goalNumber: v,
              goalTitle: i18n.language !== "es" ? goalInfoEN[v] : goalInfoES[v],
            }}
          >
            <Grid
              item
              xs={mobile ? 2 : 6}
              sm={6}
              md={4}
              style={{
                padding: 2,
                opacity:
                  !selectedGoals ||
                  selectedGoals.length === 0 ||
                  selectedGoals.indexOf(v) !== -1
                    ? 1
                    : legendOpacity,
                cursor: "pointer",
              }}
              onClick={() => onGoalClicked(v)}
            >
              <SDGIcon
                src={i18n.language !== "es" ? sdgIconsEN[v] : sdgIconsES[v]}
                className={classNames(iconClassName, "clickable")}
                alt="sdg-icon"
              />
            </Grid>
          </LegendTooltip>
        ))}
      </Grid>
    </TutorialTooltip>
  );
};

const LinkLegend = ({ selectedLinkWeights, onLinkWeightClicked, hasSelectedNode, mobile, open, onSlideChange }) => {
  const { i18n } = useTranslation();
  return (
    <TutorialTooltip
      title="8a"
      description="8b"
      placement="right"
      open={open}
      index={7}
      onSlideChange={onSlideChange}
    >
      <Grid
        container
        direction={!mobile ? "column" : "row"}
        justifyContent="center"
      >
        {Object.keys(linkInfo)
          .sort((a, b) => a - b)
          .map((v) => (
            <LegendTooltip key={v} title={linkInfo[v].linkTooltip}>
              <Grid
                item
                className={classNames(
                  "link-item",
                  hasSelectedNode ? "clickable" : "disabled-link-item"
                )}
                style={{
                  opacity:
                    !selectedLinkWeights ||
                    selectedLinkWeights.length === 0 ||
                    selectedLinkWeights.indexOf(parseInt(v)) !== -1
                      ? 1
                      : legendOpacity,
                  display: "flex",
                }}
                onClick={hasSelectedNode ? () => onLinkWeightClicked(v) : null}
              >
                <span
                  width={50}
                  style={{
                    border: `1px solid ${
                      hasSelectedNode ? linkInfo[v].linkColor : "lightgray"
                    }`,
                    backgroundColor: hasSelectedNode
                      ? linkInfo[v].linkColor
                      : "lightgray",
                    marginRight: 4,
                  }}
                >
                  &nbsp;&nbsp;&nbsp;
                </span>
                {i18n.language !== "es"
                  ? linkInfo[v].linkStatusEN
                  : linkInfo[v].linkStatusES}
              </Grid>
            </LegendTooltip>
          ))}
      </Grid>
    </TutorialTooltip>
  );
};

const DirectionLegend = ({
  selectedDirection,
  onDirectionClicked,
  hasSelectedNode,
  mobile,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  let refArrowRight = useRef();
  let refArrowLeft = useRef();
  const [arrowRight, setArrowRight] = useState();
  const [arrowLeft, setArrowLeft] = useState();
  useEffect(() => {
    const linkColor = theme.palette.primary.contrastText;
    setArrowRight(
      new Arrow(refArrowRight?.current, { rightArrow: true, linkColor })
    );
    setArrowLeft(
      new Arrow(refArrowLeft?.current, { rightArrow: false, linkColor })
    );
  }, [theme]);

  useEffect(() => {
    const clickable = hasSelectedNode;
    arrowRight?.update({ clickable });
    arrowLeft?.update({ clickable });
  }, [arrowRight, arrowLeft, hasSelectedNode]);

  return (
    <Grid
      container
      direction={!mobile ? "column" : "row"}
      justifyContent="center"
    >
      <LegendTooltip title="incomingTooltip">
        <Grid
          item
          className={classNames(
            "link-item",
            hasSelectedNode ? "clickable" : "disabled-link-item"
          )}
          style={{
            opacity:
              !selectedDirection || "incoming" === selectedDirection
                ? 1
                : legendOpacity,
            display: "flex",
          }}
          onClick={
            hasSelectedNode ? () => onDirectionClicked("incoming") : null
          }
        >
          <div ref={refArrowRight} style={{ marginRight: 4 }} />
          {t("incoming")}
        </Grid>
      </LegendTooltip>
      <LegendTooltip title="outgoingTooltip">
        <Grid
          item
          className={classNames(
            "link-item",
            hasSelectedNode ? "clickable" : "disabled-link-item"
          )}
          style={{
            opacity:
              !selectedDirection || "outgoing" === selectedDirection
                ? 1
                : legendOpacity,
            display: "flex",
          }}
          onClick={
            hasSelectedNode ? () => onDirectionClicked("outgoing") : null
          }
        >
          <div ref={refArrowLeft} style={{ marginRight: 4 }} />
          {t("outgoing")}
        </Grid>
      </LegendTooltip>
    </Grid>
  );
};

const DownloadButton = (props) => {
  const { t } = useTranslation();

  return (
    <TutorialTooltip
      title="tutorialDownloadTitle"
      description="tutorialDownloadDescription"
      placement="left"
      open={props.open}
      index={9}
      onSlideChange={props.onSlideChange}
    >
      <div>
        <LegendTooltip title="downloadTooltip">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {t("downloadImage")}
            <div>
              <CustomButtonBlue
                variant="text"
                size="small"
                onClick={() => props.onClick("jpg")}
              >
                <IconDownload fontSize="medium" style={{ marginRight: 4 }} />
                {"JPG"}
              </CustomButtonBlue>
              <CustomButtonBlue
                variant="text"
                size="small"
                onClick={() => props.onClick("png")}
              >
                <IconDownload fontSize="medium" style={{ marginRight: 4 }} />
                {"PNG"}
              </CustomButtonBlue>
            </div>
          </div>
        </LegendTooltip>
      </div>
    </TutorialTooltip>
  );
};

const BlueBox = ({
  children, style
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        padding: 5,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        fontSize: theme.typography.pxToRem(12),
        border: `1px solid ${theme.palette.primary.contrastText}`,
        ...style
      }}
    >
      {children}
    </div>
  );
}

const DesktopLegendContainer = ({
  children
}) => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="space-evenly"
      alignItems="stretch"
      style={{
        height: "100%",
      }}
    >
      {children}
    </Grid>
  );
};

const NodeInfo = ({ node, link, openInfo, openInfo2, openInteraction, onSlideChange }) => {
  const { i18n } = useTranslation();
  const nodeIndicators = Object.entries(indicatorInfo).filter((indicator) =>
    indicator[0].startsWith(node?.id)
  );
  const Node = (node) => (
    <div>
      <strong>
        {i18n.t("target")} {node.id}
      </strong>
      <br />
      {i18n.language !== "es"
        ? nodeInfo[node.id].nodeTitleEN
        : nodeInfo[node.id].nodeTitleES}
      <br />
      <br />
      <strong>
        {i18n.t("priorityScore")} = {node.priority}/10
      </strong>
      <br />
      <br />
      <i>
        <strong>{i18n.t("indicators")}</strong>
        <br />
        {nodeIndicators.map((indicator, i, arr) => {
          if (arr.length - 1 === i) {
            // last one
            return Indicator(indicator[0], indicator[1]);
          } else {
            return Indicator(indicator[0], indicator[1], true);
          }
        })}
      </i>
    </div>
  );

  const Link = (link) => (
    <div>
      <strong>
        {i18n.t("interactionBetween", {
          source: link.source,
          target: link.target,
          interactionWeight:
            i18n.language === "es"
              ? linkInfo[link.weight].linkStatusES
              : linkInfo[link.weight].linkStatusEN
        })}
      </strong>
      <br />
      <br />
      {i18n.language === "es"
        ? linksData.ES.data.filter((d) => d[""] === link.source)[0]?.[link.target]
        : linksData.EN.data.filter((d) => d[""] === link.source)[0]?.[link.target]}
      <br />
    </div>
  );

  const Indicator = (indicatorKey, indicatorInfo, breakAtEnd) => (
    <div key={indicatorKey}>
      {indicatorKey}:{" "}
      {i18n.language === "es" ? indicatorInfo.indicatorES : indicatorInfo.indicatorEN}
      <br />
      {breakAtEnd ? <br /> : null}
    </div>
  );

  return (
    <TutorialTooltip
      title="tutorialInfoTitle"
      description="tutorialInfoDescription"
      open={openInfo}
      index={4}
      placement="left"
      onSlideChange={onSlideChange}
    >
      <div>
        <TutorialTooltip
          title="6a"
          description="6b"
          open={openInfo2}
          index={5}
          placement="left"
          onSlideChange={onSlideChange}
        >
          <div>
            <TutorialTooltip
              title="tutorialInteractionTitle"
              description="tutorialInteractionDescription"
              open={openInteraction}
              index={8}
              placement="left"
              onSlideChange={onSlideChange}
            >
              <div>
                <LegendTooltip title="nodeInfoTooltip">
                  {!node && !link ? (
                    <div>
                      <strong>{i18n.t("selectATarget")}</strong>
                      <br />
                      {i18n.t("toSeeMoreInformation")}
                    </div>
                  ) : !link ? (
                    Node(node)
                  ) : (
                    Link(link)
                  )}
                </LegendTooltip>
              </div>
            </TutorialTooltip>
          </div>
        </TutorialTooltip>
      </div>
    </TutorialTooltip>
  );
};

const MobileTutorial = ({ index, onSlideChange }) => {
  return (
    <div style={{ marginTop: 100 }}>
      <TutorialTooltip
        title="tutorialNetworkTitle"
        description="tutorialNetworkDescription"
        open={index === 0}
        index={index}
        onSlideChange={onSlideChange}
        firstSlide
        mobile
      >
        <div />
      </TutorialTooltip>
      <TutorialTooltip
        title="tutorialGoalsTitle"
        description="tutorialGoalsDescription"
        open={index === 1}
        index={index}
        onSlideChange={onSlideChange}
        mobile
      >
        <div />
      </TutorialTooltip>
      <TutorialTooltip
        title="tutorialNodeTitle"
        description="tutorialNodeDescription"
        open={index === 2}
        index={index}
        onSlideChange={onSlideChange}
        mobile
      >
        <div />
      </TutorialTooltip>
      <TutorialTooltip
        title="4a"
        description="4b"
        open={index === 3}
        index={index}
        onSlideChange={onSlideChange}
        mobile
      >
        <div />
      </TutorialTooltip>
      <TutorialTooltip
        title="tutorialInfoTitle"
        description="tutorialInfoDescription"
        open={index === 4}
        placement="top"
        index={index}
        onSlideChange={onSlideChange}
        mobile
      >
        <div />
      </TutorialTooltip>
      <TutorialTooltip
        title="6a"
        description="6b"
        open={index === 5}
        placement="top"
        index={index}
        onSlideChange={onSlideChange}
        mobile
      >
        <div />
      </TutorialTooltip>
      <TutorialTooltip
        title="tutorialInteractionsTitle"
        description="tutorialInteractionsDescription"
        open={index === 6}
        placement="top"
        index={index}
        onSlideChange={onSlideChange}
        mobile
      >
        <div />
      </TutorialTooltip>
      <TutorialTooltip
        title="8a"
        description="8b"
        open={index === 7}
        placement="top"
        index={index}
        onSlideChange={onSlideChange}
        mobile
      >
        <div />
      </TutorialTooltip>
      <TutorialTooltip
        title="tutorialInteractionTitle"
        description="tutorialInteractionDescription"
        open={index === 8}
        placement="top"
        index={index}
        onSlideChange={onSlideChange}
        mobile
      >
        <div />
      </TutorialTooltip>
      <TutorialTooltip
        title="tutorialSummaryTitle"
        description="tutorialSummaryDescription"
        open={index === 9}
        placement="top"
        index={index}
        onSlideChange={onSlideChange}
        lastSlide
        mobile
      >
        <div />
      </TutorialTooltip>
    </div>
  );
};

class NetworkComponent extends Component {
  constructor(props) {
    super(props);
    this.refNetworkComponent = React.createRef();
    const searchParams = new URL(window.location.href).searchParams;
    // xxTODO: Array
    // When the site is loaded with URL parameters, the network is loaded with one selected node as given
    // by the URL parameters. It is currently not possible to select multiple nodes this way.
    const initialNodeID = searchParams.get("selectedNode");
    const selectedNode = Object.keys(nodeInfo).includes(initialNodeID)
      ? { id: String(initialNodeID) }
      : undefined;
    const selectedNodes = selectedNode ? [selectedNode] : [];
    this.state = {
      data,
      selectedLink: undefined,
      // Array of node objects
      selectedNodes: selectedNodes,
      selectedGoals: [],
      selectedLinkWeights: [],
      selectedDirection: undefined,
      width: undefined,
      height: undefined,
      mobileNetworkWidth: window.innerWidth - 40,
      mobileNetworkHeight: window.innerHeight * 0.7,
      shouldJiggle: false,
    };
  }

  async componentDidMount() {
    // Add an event listener that fires when the window get's resized
    window.addEventListener("resize", this.onResize, false);
    // Add an event listener that fires when the user presses the arrow right key
    window.addEventListener("keydown", (event) => {
      switch (event.key) {
        case "ArrowLeft":
          // Left pressed
          this.onSlideChange(true);
          break;
        case "ArrowRight":
          // Right pressed
          this.onSlideChange();
          break;
        // Escape pressed
        case "Escape":
          this.onSlideChange(false, true);
          break;
        default:
          break;
      }
      return;
    });
    this.initData();
  }

  initData() {
    const height = parseInt(d3.select("#network").style("height"));
    const width = parseInt(d3.select("#network").style("width"));
    const centerX = width / 2;
    const centerY = height / 2;
    let growthAngle = 0;
    let deltaAngle = 137.5;
    let d2r = Math.PI / 180;
    let growthDist = 0;
    let counter = 1;
    data.nodes.sort((a, b) => b.priority - a.priority);
    data.nodes.forEach((node, index) => {
      // Get node color
      node.color = sdgColors[this.nodeGoal(node)];
      // Get initial position of the node
      growthAngle += deltaAngle;
      // establish point x and y
      const penX = Math.cos(growthAngle * d2r) * centerX * growthDist;
      const penY = Math.sin(growthAngle * d2r) * centerY * growthDist;

      node.x = penX + centerX;
      node.y = penY + centerY;
      // growth dist will give x and Y with simple trig
      const indexModulo = index % 5;
      if (indexModulo === 0) {
        growthDist += 0.35 / counter;
        counter++;
      }
    });
    // Get the color for the links
    data.links.forEach(
      (link) => (link.color = linkInfo[link.weight].linkColor)
    );
    this.setState({ data, width, height });
  }

  onResize = () => {
    if (this.rqf) {
      return;
    }
    this.rqf = window.requestAnimationFrame(() => {
      this.rqf = null;
      // Recalculate nodes positions
      const { height, width } = this.state;
      const newHeight = parseInt(d3.select("#network").style("height"));
      const newWidth = parseInt(d3.select("#network").style("width"));

      const widthAspect = newWidth / width;
      const heightAspect = newHeight / height;

      data.nodes.forEach((node, index) => {
        node.x = node.x * widthAspect;
        node.y = node.y * heightAspect;
      });
      this.setState({ height: newHeight, width: newWidth });
    });
  };

  onSlideChange = (backwards, closeTutorial) => {
    const { tutorialOpen, tutorialIndex, onTutorialClick } = this.props;
    if (!tutorialOpen) {
      return;
    }

    // Set the new state depending on the new index
    const baseState = {
      selectedGoals: null,
      selectedNodes: [],
      selectedLinkWeights: [],
      selectedLink: null,
      selectedDirection: null,
      shouldJiggle: false,
    };
    // Reset the state
    this.setState(baseState);
    if (closeTutorial) {
      // Reset the tutorial index and close the tutorial
      onTutorialClick(0, false);
      return;
    }

    const newIndex = backwards ? tutorialIndex - 1 : tutorialIndex + 1;
    onTutorialClick(newIndex, tutorialIndex !== 10);

    if (newIndex === 1) {
      this.setState({ selectedGoals: ["16"] });
    }
    if (newIndex === 2) {
      this.setState({ shouldJiggle: true });
    }
    if (newIndex >= 2) {
      this.setState({ selectedNodes: [{ id: "16.5" }] });
    }
    if (newIndex === 3) {
      this.setState({ selectedNodes: [{ id: "16.5" }, { id: "6.2" }] });
    } 
    if (newIndex >= 6) {
      this.setState({ selectedDirection: "outgoing" });
    } 
    if (newIndex >= 7) {
      this.setState({ selectedLinkWeights: [3] });
    } 
    if (newIndex >= 8) {
      this.setState({
        selectedLink: { source: "16.5", target: "5.2", weight: 3 },
      });
    } 
    if (newIndex === 11) {
      this.setState({ selectedGoals: [] });
    }

    // TODO: add scrolling on keyboard arrow press, doesn't work atm
    // If there are no more tutorial slides to show
    if (newIndex === 11) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return;
    }
    if ((newIndex === 0 || newIndex === 2 || newIndex === 3) && !onMobile) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
      return;
    }
    if (newIndex === 10 && !onMobile) {
      const infoboxElement = document.getElementById("backgroundTab");
      infoboxElement.scrollIntoView({
        inline: "center",
        block: "center",
        behavior: "smooth",
      });
      return;
    }

    const networkElement = document.getElementById("network");
    networkElement.scrollIntoView({
      inline: "center",
      block: "center",
      behavior: "smooth",
    });
  };

  nodeGoal = (node) => {
    return node.id.split(".")[0];
  };

  outgoingLinks(node) {
    return data.links.filter((link) => link.source === node.id);
  }

  onGoalClicked = (goal) => {
    const { selectedGoals } = this.state;
    const indexOfGoal = selectedGoals.indexOf(goal);
    this.setState({
      selectedGoals:
        indexOfGoal !== -1
          ? selectedGoals.filter((d, index) => index !== indexOfGoal)
          : selectedGoals.concat(goal),
    });
  };

  onLinkWeightClicked = (linkWeight) => {
    const { selectedLinkWeights } = this.state;
    const indexOfLinkWeight = selectedLinkWeights.indexOf(parseInt(linkWeight));
    this.setState({
      selectedLinkWeights:
        indexOfLinkWeight !== -1
          ? selectedLinkWeights.filter(
              (d, index) => index !== indexOfLinkWeight
            )
          : selectedLinkWeights.concat(parseInt(linkWeight)),
    });
  };

  onDirectionClicked = (direction) => {
    const { selectedDirection } = this.state;
    this.setState({
      selectedDirection: selectedDirection === direction ? null : direction,
    });
  };

  onNetworkClickNode = (d) => {
    const { selectedNodes } = this.state;
    const l = selectedNodes.filter((node) => node?.id === d.id).length;
    let newNodes = [].concat(selectedNodes);
    // If the clicked upon node is not in selectedNodes add it otherwise remove it
    if (l === 0) {
      newNodes.push(d);
    } else {
      newNodes = newNodes.filter((node) => node?.id !== d.id);
    }
    // Select a node and remove a selected link
    this.setState({
      selectedNodes: newNodes,
      selectedLink: null,
    });
  };

  onNetworkClickLink = (d) => {
    const { selectedLink } = this.state;
    // Select a link
    this.setState({
      // TODO: replace with link id, but data has no id
      selectedLink:
        selectedLink?.source + selectedLink?.target === d.source + d.target
          ? null
          : d,
    });
  };

  onNetworkClickBackground = () => {
    this.setState({
      selectedGoals: [],
      selectedNodes: [],
      selectedLink: null,
    });
  };

  onClickSaveNetwork = async (fileType) => {
    const element = this.refNetworkComponent.current;
    const canvas = await html2canvas(element);
    const data = canvas.toDataURL(`image/${fileType}`);
    const link = document.createElement("a");
    if (typeof link.download === "string") {
      link.href = data;
      link.download = `ods-galapagos.${fileType}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(data);
    }
  };

  renderChart(style) {
    const { tutorialOpen } = this.props;
    const {
      data,
      selectedLink,
      selectedNodes,
      selectedGoals,
      selectedLinkWeights,
      selectedDirection,
      shouldJiggle,
    } = this.state;
    const handDownProps = {
      networkRef: this.refNetworkComponent,
      data,
      selectedLink,
      selectedNodes,
      selectedGoals,
      selectedLinkWeights,
      selectedDirection,
      onClick: this.onNetworkClickNode,
      onLinkClick: this.onNetworkClickLink,
      onWhiteSpaceClick: this.onNetworkClickBackground,
      shouldJiggle,
      tooltip: (d) => `
        <div>
          ${d.id}
          <br />
          ${
            this.props.i18n.language !== "es"
              ? nodeInfo[d.id].nodeTitleEN
              : nodeInfo[d.id].nodeTitleES
          }
          <br />
          ${this.props.i18n.t("priorityScore")} = ${d.priority}/10
        </div>
      `,
      onMobile,
      tutorialOpen,
    };

    if (data.length === 0) {
      return "No data yet";
    }
    return <NetworkWrapper style={style} {...handDownProps} />;
  }

  renderDesktop() {
    const {
      selectedNodes,
      selectedLink,
      selectedGoals,
      selectedDirection,
      selectedLinkWeights,
    } = this.state;
    const height = window.innerHeight;
    const minHeight = 700;
    const legendsHeight = height * 0.8;
    const selectedNode = selectedNodes?.[selectedNodes.length - 1];
    const hasSelectedNode = !!selectedNode;
    return (
      <Grid container direction="column">
        <Grid
          container
          direction="row"
          style={{ height, minHeight, alignItems: "center" }}
          justifyContent="space-between"
        >
          <Grid
            item
            xs={2}
            style={{
              padding: 0,
              height: legendsHeight,
              minHeight,
            }}
          >
            <DesktopLegendContainer>
              <BlueBox>
                <Typography variant="h6">
                  {this.props.i18n.t("filterByGoal")}
                </Typography>
                <Grid
                  item
                  xs
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexBasis: "auto",
                    maxHeight: legendsHeight * 0.75,
                  }}
                >
                  <SDGLegend
                    selectedGoals={selectedGoals}
                    onGoalClicked={(goal) => this.onGoalClicked(goal)}
                    open={this.props.openOnDesktop(1)}
                    iconClassName="sdg-icon"
                    onSlideChange={this.onSlideChange}
                  />
                </Grid>
              </BlueBox>
              <BlueBox>
                <Typography variant="h6">
                  {this.props.i18n.t("filterByInteraction")}
                </Typography>
                <Grid
                  item
                  xs
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexBasis: "auto",
                    maxHeight: legendsHeight * 0.2,
                  }}
                >
                  <TutorialTooltip
                    title="tutorialInteractionsTitle"
                    description="tutorialInteractionsDescription"
                    placement="right"
                    open={this.props.openOnDesktop(6)}
                    index={6}
                    onSlideChange={this.onSlideChange}
                  >
                    <Grid
                      container
                      direction="row"
                      style={{ alignItems: "center" }}
                    >
                      <Grid
                        item
                        xs={6}
                        style={{
                          padding: 0,
                        }}
                      >
                        <DirectionLegend
                          selectedDirection={selectedDirection}
                          onDirectionClicked={(direction) =>
                            this.onDirectionClicked(direction)
                          }
                          hasSelectedNode={hasSelectedNode}
                          open={this.props.openOnDesktop(6)}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          padding: 0,
                        }}
                      >
                        <LinkLegend
                          selectedLinkWeights={selectedLinkWeights}
                          onLinkWeightClicked={(linkWeight) =>
                            this.onLinkWeightClicked(linkWeight)
                          }
                          hasSelectedNode={hasSelectedNode}
                          open={this.props.openOnDesktop(7)}
                          onSlideChange={this.onSlideChange}
                        />
                      </Grid>
                    </Grid>
                  </TutorialTooltip>
                </Grid>
              </BlueBox>
            </DesktopLegendContainer>
          </Grid>
          <TutorialTooltip
            title="tutorialNodeTitle"
            description="tutorialNodeDescription"
            open={this.props.openOnDesktop(2)}
            index={2}
            onSlideChange={this.onSlideChange}
          >
            <Grid item xs={8} style={{ padding: 0 }}>
              <TutorialTooltip
                title="4a"
                description="4b"
                open={this.props.openOnDesktop(3)}
                index={3}
                onSlideChange={this.onSlideChange}
              >
                <div>
                  {this.renderChart({
                    height,
                    maxWidth: height,
                    margin: "0 auto",
                    position: "relative",
                  })}
                </div>
              </TutorialTooltip>
            </Grid>
          </TutorialTooltip>
          <Grid
            item
            xs={2}
            style={{
              padding: 0,
              height: legendsHeight,
              minHeight,
            }}
          >
            <DesktopLegendContainer>
              <BlueBox>
                <Typography variant="h6">
                  {this.props.i18n.t("information")}
                </Typography>
                <Grid
                  item
                  xs
                  style={{
                    display: "flex",
                    alignItems:
                      hasSelectedNode || !!selectedLink ? "start" : "center",
                    justifyContent: "center",
                    flexBasis: "auto",
                    padding: 0,
                    maxHeight: legendsHeight * 0.75,
                    overflow: "scroll",
                  }}
                  className="hidescrollbar"
                >
                  <NodeInfo
                    node={selectedNode}
                    link={selectedLink}
                    openInfo={this.props.openOnDesktop(4)}
                    openInfo2={this.props.openOnDesktop(5)}
                    openInteraction={this.props.openOnDesktop(8)}
                    onSlideChange={this.onSlideChange}
                  />
                </Grid>
              </BlueBox>
              <BlueBox>
                <Typography variant="h6">
                  {this.props.i18n.t("download")}
                </Typography>
                <Grid
                  item
                  xs
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexBasis: "auto",
                    padding: 0,
                    maxHeight: legendsHeight * 0.2,
                  }}
                >
                  <DownloadButton
                    onClick={this.onClickSaveNetwork}
                    open={this.props.openOnDesktop(9)}
                    onSlideChange={this.onSlideChange}
                  />
                </Grid>
              </BlueBox>
            </DesktopLegendContainer>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderMobile() {
    const { tutorialOpen, tutorialIndex } = this.props;
    const {
      selectedNodes,
      selectedLink,
      selectedGoals,
      selectedLinkWeights,
      selectedDirection,
      mobileNetworkHeight,
      mobileNetworkWidth
    } = this.state;
    const selectedNode = selectedNodes?.[selectedNodes.length - 1];
    const hasSelectedNode = !!selectedNode;
    return (
      <Grid container direction="column">
        <div style={{ width: "100%" }}>
          <BlueBox>
            <Typography variant="h6">
              {this.props.i18n.t("filterByGoal")}
            </Typography>
            <SDGLegend
              mobile
              selectedGoals={selectedGoals}
              onGoalClicked={(goal) => this.onGoalClicked(goal)}
              iconClassName="sdg-icon-mobile"
              onSlideChange={this.onSlideChange}
            />
          </BlueBox>
          <BlueBox
            style={{
              marginBottom: 16,
            }}
          >
            <Typography variant="h6">
              {this.props.i18n.t("filterByInteraction")}
            </Typography>
            <LinkLegend
              mobile
              selectedLinkWeights={selectedLinkWeights}
              onLinkWeightClicked={(linkWeight) =>
                this.onLinkWeightClicked(linkWeight)
              }
              hasSelectedNode={hasSelectedNode}
              onSlideChange={this.onSlideChange}
            />
            <DirectionLegend
              mobile
              selectedDirection={selectedDirection}
              onDirectionClicked={(direction) =>
                this.onDirectionClicked(direction)
              }
              hasSelectedNode={hasSelectedNode}
            />
          </BlueBox>

          {this.renderChart({
            height: mobileNetworkHeight,
            maxWidth: mobileNetworkWidth,
            margin: "0 auto",
            marginBottom: 16,
            position: "relative",
          })}
          {tutorialOpen ? (
            <MobileTutorial
              index={tutorialIndex}
              onSlideChange={this.onSlideChange}
            />
          ) : null}
          <BlueBox
            style={{
              marginTop: 16,
            }}
          >
            <Typography variant="h6">
              {this.props.i18n.t("information")}
            </Typography>
            <NodeInfo
              node={selectedNode}
              link={selectedLink}
              onSlideChange={this.onSlideChange}
            />
          </BlueBox>
        </div>
      </Grid>
    );
  }

  render() {
    return (
      <div>
        <TutorialTooltip
          title="tutorialNetworkTitle"
          description="tutorialNetworkDescription"
          open={this.props.openOnDesktop(0)}
          index={0}
          onSlideChange={this.onSlideChange}
          firstSlide
        >
          {onMobile ? this.renderMobile() : this.renderDesktop()}
        </TutorialTooltip>
      </div>
    );
  }
}

export default withTranslation()(NetworkComponent);
