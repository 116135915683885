export const data = {
  directed: true,
  multigraph: true,
  graph: {},
  nodes: [
    {
      priority: 9.92,
      id: "1.4",
    },
    {
      priority: 3.47,
      id: "2.2",
    },
    {
      priority: 5.32,
      id: "2.3",
    },
    {
      priority: 5.06,
      id: "2.4",
    },
    {
      priority: 3.32,
      id: "2.a",
    },
    {
      priority: 5.29,
      id: "3.5",
    },
    {
      priority: 6.41,
      id: "3.7",
    },
    {
      priority: 5.25,
      id: "3.8",
    },
    {
      priority: 5.73,
      id: "3.c",
    },
    {
      priority: 6.99,
      id: "4.1",
    },
    {
      priority: 3.42,
      id: "4.2",
    },
    {
      priority: 7.12,
      id: "4.3",
    },
    {
      priority: 6.7,
      id: "4.4",
    },
    {
      priority: 8.95,
      id: "4.7",
    },
    {
      priority: 8.34,
      id: "4.c",
    },
    {
      priority: 9.12,
      id: "5.2",
    },
    {
      priority: 3.28,
      id: "6.1",
    },
    {
      priority: 3.86,
      id: "6.2",
    },
    {
      priority: 4.04,
      id: "6.3",
    },
    {
      priority: 2.72,
      id: "6.4",
    },
    {
      priority: 4.87,
      id: "7.b",
    },
    {
      priority: 7.35,
      id: "8.2",
    },
    {
      priority: 6.18,
      id: "8.6",
    },
    {
      priority: 6.9,
      id: "8.9",
    },
    {
      priority: 6.9,
      id: "9.5",
    },
    {
      priority: 5.19,
      id: "9.c",
    },
    {
      priority: 4.85,
      id: "11.2",
    },
    {
      priority: 6.05,
      id: "11.3",
    },
    {
      priority: 8.9,
      id: "11.4",
    },
    {
      priority: 9.41,
      id: "11.b",
    },
    {
      priority: 4.72,
      id: "12.2",
    },
    {
      priority: 3.58,
      id: "12.5",
    },
    {
      priority: 3.89,
      id: "14.1",
    },
    {
      priority: 6.71,
      id: "14.2",
    },
    {
      priority: 4.76,
      id: "14.4",
    },
    {
      priority: 6.59,
      id: "15.5",
    },
    {
      priority: 2.81,
      id: "15.7",
    },
    {
      priority: 6.08,
      id: "15.8",
    },
    {
      priority: 3.97,
      id: "16.2",
    },
    {
      priority: 10.0,
      id: "16.5",
    },
  ],
  links: [
    {
      weight: 2.0,
      source: "1.4",
      target: "2.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "2.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "1.4",
      target: "2.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "1.4",
      target: "3.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "1.4",
      target: "3.7",
      key: 0,
    },
    {
      weight: 3.0,
      source: "1.4",
      target: "3.8",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "3.c",
      key: 0,
    },
    {
      weight: 3.0,
      source: "1.4",
      target: "4.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "4.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "4.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "1.4",
      target: "4.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "5.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "1.4",
      target: "6.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "1.4",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "6.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "1.4",
      target: "6.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "7.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "1.4",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "1.4",
      target: "8.9",
      key: 0,
    },
    {
      weight: 3.0,
      source: "1.4",
      target: "9.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "1.4",
      target: "9.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "11.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "1.4",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "1.4",
      target: "12.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "1.4",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "1.4",
      target: "14.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "14.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "1.4",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "1.4",
      target: "15.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "16.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "1.4",
      target: "16.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.2",
      target: "2.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "3.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "3.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "3.8",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.2",
      target: "4.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.2",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "4.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "4.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "6.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "6.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.2",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.2",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "14.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.2",
      target: "16.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.3",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.3",
      target: "2.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "2.3",
      target: "2.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "2.3",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "3.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "3.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "4.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "4.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "6.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.3",
      target: "6.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.3",
      target: "6.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "7.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "2.3",
      target: "8.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "8.9",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.3",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "11.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.3",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.3",
      target: "12.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.3",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "14.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.3",
      target: "14.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.3",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "15.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.3",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "16.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.3",
      target: "16.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.4",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.4",
      target: "2.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "2.4",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.4",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.4",
      target: "3.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.4",
      target: "5.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.4",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.4",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.4",
      target: "6.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.4",
      target: "6.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "2.4",
      target: "7.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.4",
      target: "8.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.4",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.4",
      target: "8.9",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.4",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.4",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.4",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.4",
      target: "11.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "2.4",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.4",
      target: "12.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.4",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.4",
      target: "14.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "2.4",
      target: "14.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.4",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.4",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.a",
      target: "2.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "2.a",
      target: "2.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "2.a",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "3.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "4.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "4.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "5.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.a",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "6.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "6.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.a",
      target: "6.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.a",
      target: "7.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "2.a",
      target: "8.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "8.9",
      key: 0,
    },
    {
      weight: 3.0,
      source: "2.a",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "9.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.a",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "12.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "2.a",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "2.a",
      target: "16.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.5",
      target: "2.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "2.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "3.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "3.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "3.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.5",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "4.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.5",
      target: "4.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.5",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.5",
      target: "4.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.5",
      target: "5.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.5",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.5",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "11.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "11.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "12.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "14.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.5",
      target: "15.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.5",
      target: "16.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.5",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "3.7",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.7",
      target: "2.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.7",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.7",
      target: "3.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "3.7",
      target: "3.8",
      key: 0,
    },
    {
      weight: 3.0,
      source: "3.7",
      target: "3.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.7",
      target: "4.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.7",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.7",
      target: "4.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.7",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.7",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.7",
      target: "4.c",
      key: 0,
    },
    {
      weight: 3.0,
      source: "3.7",
      target: "5.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.7",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.7",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.7",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.7",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.7",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.7",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.7",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.7",
      target: "12.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.7",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.7",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.7",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.7",
      target: "16.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.7",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "3.8",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.8",
      target: "2.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.8",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.8",
      target: "3.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "3.8",
      target: "3.7",
      key: 0,
    },
    {
      weight: 3.0,
      source: "3.8",
      target: "3.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.8",
      target: "4.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.8",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.8",
      target: "4.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.8",
      target: "4.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.8",
      target: "4.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.8",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.8",
      target: "8.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.8",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.8",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.8",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.8",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.8",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.8",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.8",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.8",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.8",
      target: "16.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.8",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "3.c",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.c",
      target: "2.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.c",
      target: "3.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "3.c",
      target: "3.7",
      key: 0,
    },
    {
      weight: 3.0,
      source: "3.c",
      target: "3.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "4.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "4.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "4.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.c",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "6.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "6.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "6.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "7.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.c",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.c",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "11.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "12.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "3.c",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "3.c",
      target: "16.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.1",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "2.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "2.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "3.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "3.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.1",
      target: "3.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.1",
      target: "3.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.1",
      target: "4.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.1",
      target: "4.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.1",
      target: "4.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.1",
      target: "4.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "4.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.1",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.1",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "6.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "6.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.1",
      target: "7.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.1",
      target: "8.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.1",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.1",
      target: "8.9",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.1",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.1",
      target: "9.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.1",
      target: "11.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.1",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.1",
      target: "11.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "12.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "14.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.1",
      target: "14.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "15.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "15.8",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.1",
      target: "16.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.1",
      target: "16.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.2",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.2",
      target: "2.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.2",
      target: "3.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.2",
      target: "3.7",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.2",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.2",
      target: "4.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.2",
      target: "4.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.2",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.2",
      target: "4.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.2",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.2",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.2",
      target: "9.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.2",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.2",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.2",
      target: "16.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.2",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.3",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "2.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "2.a",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.3",
      target: "3.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.3",
      target: "3.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "3.8",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.3",
      target: "3.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.3",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "4.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.3",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.3",
      target: "4.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.3",
      target: "4.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.3",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "6.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "6.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "6.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "7.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.3",
      target: "8.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.3",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "8.9",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.3",
      target: "9.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.3",
      target: "9.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "11.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.3",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "12.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "14.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "15.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.3",
      target: "16.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.3",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.4",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "2.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "2.a",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "3.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "3.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "3.8",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "3.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "4.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.4",
      target: "4.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "4.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "4.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "6.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "6.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "7.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.4",
      target: "8.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.4",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "8.9",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.4",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "9.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "12.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "14.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "15.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.4",
      target: "16.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.4",
      target: "16.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.7",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.7",
      target: "2.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.7",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.7",
      target: "3.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "3.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "4.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "4.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "4.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.7",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "4.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "5.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.7",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "6.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "6.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "7.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.7",
      target: "9.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "11.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.7",
      target: "11.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "11.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.7",
      target: "12.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.7",
      target: "12.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.7",
      target: "14.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.7",
      target: "14.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "14.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.7",
      target: "15.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.7",
      target: "15.7",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.7",
      target: "15.8",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "16.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.7",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.c",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "2.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.c",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "2.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "3.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "3.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.c",
      target: "3.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.c",
      target: "3.c",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.c",
      target: "4.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.c",
      target: "4.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.c",
      target: "4.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.c",
      target: "4.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.c",
      target: "4.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.c",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.c",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "6.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "6.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.c",
      target: "7.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.c",
      target: "8.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "4.c",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.c",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.c",
      target: "9.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.c",
      target: "11.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "12.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "14.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.c",
      target: "14.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "15.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "15.8",
      key: 0,
    },
    {
      weight: 2.0,
      source: "4.c",
      target: "16.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "4.c",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "5.2",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "5.2",
      target: "2.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "2.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "2.a",
      key: 0,
    },
    {
      weight: 3.0,
      source: "5.2",
      target: "3.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "5.2",
      target: "3.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "5.2",
      target: "3.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "3.c",
      key: 0,
    },
    {
      weight: 3.0,
      source: "5.2",
      target: "4.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "5.2",
      target: "4.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "5.2",
      target: "4.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "5.2",
      target: "4.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "5.2",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "4.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "6.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "6.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "6.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "5.2",
      target: "8.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "5.2",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "5.2",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "9.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "11.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "5.2",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "12.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "14.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "15.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "5.2",
      target: "15.8",
      key: 0,
    },
    {
      weight: 3.0,
      source: "5.2",
      target: "16.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "5.2",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.1",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.1",
      target: "2.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "2.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "3.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "3.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "4.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.1",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.1",
      target: "6.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.1",
      target: "6.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "7.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "8.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "9.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.1",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.1",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "12.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.1",
      target: "14.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.2",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.2",
      target: "2.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "2.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "3.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "3.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "4.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.2",
      target: "6.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.2",
      target: "6.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "6.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "9.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.2",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.2",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "12.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.2",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.2",
      target: "15.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.3",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.3",
      target: "2.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.3",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.3",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.3",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.3",
      target: "3.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.3",
      target: "3.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.3",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.3",
      target: "4.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.3",
      target: "6.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.3",
      target: "6.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.3",
      target: "6.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.3",
      target: "7.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.3",
      target: "8.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.3",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.3",
      target: "8.9",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.3",
      target: "9.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.3",
      target: "11.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.3",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.3",
      target: "11.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.3",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.3",
      target: "12.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.3",
      target: "14.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.3",
      target: "15.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.4",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.4",
      target: "2.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.4",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.4",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.4",
      target: "3.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.4",
      target: "3.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.4",
      target: "4.7",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.4",
      target: "6.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.4",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.4",
      target: "6.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.4",
      target: "8.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.4",
      target: "8.9",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.4",
      target: "9.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.4",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "6.4",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.4",
      target: "11.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "6.4",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "6.4",
      target: "15.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "7.b",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "7.b",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "7.b",
      target: "2.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "7.b",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "7.b",
      target: "3.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "7.b",
      target: "3.8",
      key: 0,
    },
    {
      weight: 2.0,
      source: "7.b",
      target: "3.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "7.b",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "7.b",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "7.b",
      target: "4.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "7.b",
      target: "4.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "7.b",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "7.b",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "7.b",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "7.b",
      target: "6.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "7.b",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "7.b",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "7.b",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "7.b",
      target: "9.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "7.b",
      target: "9.c",
      key: 0,
    },
    {
      weight: 3.0,
      source: "7.b",
      target: "11.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "7.b",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "7.b",
      target: "11.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "7.b",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "7.b",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "7.b",
      target: "12.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "7.b",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "7.b",
      target: "14.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "7.b",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "7.b",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "7.b",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.2",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.2",
      target: "2.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.2",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "2.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.2",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.2",
      target: "3.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.2",
      target: "3.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "3.8",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "3.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.2",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.2",
      target: "4.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "4.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.2",
      target: "4.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.2",
      target: "4.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "4.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.2",
      target: "5.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "6.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "6.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "6.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "7.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.2",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "8.9",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.2",
      target: "9.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "9.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "11.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "11.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "12.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.2",
      target: "14.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "14.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "14.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.2",
      target: "15.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.2",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.2",
      target: "16.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.2",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.6",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "2.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.6",
      target: "2.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "2.a",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.6",
      target: "3.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.6",
      target: "3.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "3.8",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.6",
      target: "3.c",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.6",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "4.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.6",
      target: "4.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.6",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.6",
      target: "4.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.6",
      target: "4.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.6",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "6.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "6.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "6.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "7.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.6",
      target: "8.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "8.9",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.6",
      target: "9.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.6",
      target: "9.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "11.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.6",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "12.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "14.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "15.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.6",
      target: "16.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.6",
      target: "16.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.9",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "2.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.9",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.9",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "3.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "3.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "3.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "4.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "4.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.9",
      target: "6.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.9",
      target: "6.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.9",
      target: "7.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.9",
      target: "8.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "9.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.9",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.9",
      target: "11.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.9",
      target: "11.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.9",
      target: "11.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.9",
      target: "12.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "8.9",
      target: "12.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.9",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "14.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.9",
      target: "14.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.9",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.9",
      target: "15.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "8.9",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "8.9",
      target: "16.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.5",
      target: "2.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "2.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "2.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "2.a",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "3.7",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "3.8",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "3.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.5",
      target: "4.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "4.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.5",
      target: "4.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.5",
      target: "5.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "6.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "6.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "6.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "6.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "7.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "8.9",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "9.c",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "11.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "11.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "12.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "14.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.5",
      target: "14.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "14.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "15.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.5",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.5",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.c",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "2.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "2.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "2.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.c",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "3.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.c",
      target: "3.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.c",
      target: "3.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "3.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.c",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "4.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.c",
      target: "4.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.c",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.c",
      target: "4.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.c",
      target: "4.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.c",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "6.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.c",
      target: "8.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.c",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "9.c",
      target: "8.9",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.c",
      target: "9.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "9.c",
      target: "11.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "14.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "15.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "16.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "9.c",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.2",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.2",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.2",
      target: "3.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "3.8",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "4.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "4.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "4.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.2",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.2",
      target: "6.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "6.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.2",
      target: "7.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.2",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "9.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.2",
      target: "11.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.2",
      target: "11.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "11.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "12.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.2",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.2",
      target: "14.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.2",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.2",
      target: "15.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.2",
      target: "15.8",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.3",
      target: "2.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.3",
      target: "2.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.3",
      target: "2.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.3",
      target: "3.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.3",
      target: "3.7",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "3.8",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "3.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.3",
      target: "4.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.3",
      target: "4.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.3",
      target: "4.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.3",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.3",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.3",
      target: "5.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "6.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "6.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "6.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "6.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "7.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.3",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.3",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.3",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.3",
      target: "9.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "9.c",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "11.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "11.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.3",
      target: "11.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "12.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "12.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.3",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.3",
      target: "14.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.3",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.3",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.3",
      target: "16.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.3",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.4",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.4",
      target: "2.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.4",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.4",
      target: "3.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.4",
      target: "3.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.4",
      target: "3.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.4",
      target: "3.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "4.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.4",
      target: "4.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "4.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "4.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "5.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "6.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "6.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "6.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "7.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.4",
      target: "9.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "11.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.4",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.4",
      target: "12.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.4",
      target: "14.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.4",
      target: "14.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.4",
      target: "14.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.4",
      target: "15.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.4",
      target: "15.7",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.4",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.4",
      target: "16.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.4",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.b",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.b",
      target: "2.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "2.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.b",
      target: "2.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.b",
      target: "3.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "3.7",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.b",
      target: "3.8",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.b",
      target: "3.c",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.b",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.b",
      target: "4.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "4.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.b",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "4.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "4.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "5.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.b",
      target: "6.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "6.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.b",
      target: "6.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "7.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "9.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "9.c",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.b",
      target: "11.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.b",
      target: "11.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.b",
      target: "11.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.b",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "12.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "14.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.b",
      target: "14.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "14.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "11.b",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.b",
      target: "15.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "11.b",
      target: "16.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "11.b",
      target: "16.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "2.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "2.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "12.2",
      target: "2.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.2",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.2",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.2",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.2",
      target: "4.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.2",
      target: "5.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "6.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "6.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "12.2",
      target: "6.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "7.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "8.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.2",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.2",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "11.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "12.2",
      target: "11.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "11.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.2",
      target: "12.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.2",
      target: "14.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "12.2",
      target: "14.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "12.2",
      target: "14.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "12.2",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.2",
      target: "15.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.2",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.2",
      target: "16.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.5",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.5",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.5",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.5",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.5",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.5",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.5",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.5",
      target: "4.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.5",
      target: "6.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.5",
      target: "6.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.5",
      target: "7.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.5",
      target: "8.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.5",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.5",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.5",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.5",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.5",
      target: "11.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.5",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.5",
      target: "11.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "12.5",
      target: "12.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "12.5",
      target: "14.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "12.5",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "12.5",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "2.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.1",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "4.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "4.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "6.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "6.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "6.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.1",
      target: "7.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "8.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "8.9",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "11.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "11.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.1",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.1",
      target: "12.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.1",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "14.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.1",
      target: "15.8",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.2",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.2",
      target: "2.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.2",
      target: "2.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.2",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.2",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.2",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.2",
      target: "4.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.2",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.2",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.2",
      target: "4.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.2",
      target: "5.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.2",
      target: "7.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.2",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.2",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.2",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.2",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.2",
      target: "9.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.2",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.2",
      target: "11.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.2",
      target: "11.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.2",
      target: "11.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.2",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.2",
      target: "12.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.2",
      target: "14.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.2",
      target: "14.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.2",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.2",
      target: "15.7",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.2",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.2",
      target: "16.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.4",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.4",
      target: "2.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.4",
      target: "2.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.4",
      target: "2.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.4",
      target: "3.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.4",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.4",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.4",
      target: "4.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.4",
      target: "4.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.4",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.4",
      target: "4.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.4",
      target: "5.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.4",
      target: "8.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.4",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.4",
      target: "8.9",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.4",
      target: "9.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.4",
      target: "11.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.4",
      target: "11.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.4",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.4",
      target: "12.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.4",
      target: "14.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "14.4",
      target: "14.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.4",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.4",
      target: "15.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.4",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "14.4",
      target: "16.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "14.4",
      target: "16.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "2.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "15.5",
      target: "2.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "15.5",
      target: "2.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.5",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.5",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.5",
      target: "4.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.5",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.5",
      target: "4.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.5",
      target: "5.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.5",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "6.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.5",
      target: "6.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "7.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.5",
      target: "9.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "11.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "11.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "15.5",
      target: "11.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "11.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "15.5",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "12.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "14.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.5",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.5",
      target: "14.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "15.5",
      target: "15.7",
      key: 0,
    },
    {
      weight: 3.0,
      source: "15.5",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.5",
      target: "16.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.7",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.7",
      target: "3.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.7",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.7",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.7",
      target: "4.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.7",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.7",
      target: "4.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.7",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.7",
      target: "9.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "15.7",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.7",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.7",
      target: "14.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.7",
      target: "14.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "15.7",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.7",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.7",
      target: "16.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.7",
      target: "16.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.8",
      target: "1.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.8",
      target: "2.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "15.8",
      target: "2.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "15.8",
      target: "2.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.8",
      target: "2.a",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.8",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.8",
      target: "4.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.8",
      target: "4.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.8",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.8",
      target: "4.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.8",
      target: "4.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.8",
      target: "6.3",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.8",
      target: "7.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.8",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.8",
      target: "8.6",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.8",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.8",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.8",
      target: "9.c",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.8",
      target: "11.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.8",
      target: "11.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "15.8",
      target: "11.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.8",
      target: "11.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.8",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.8",
      target: "14.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "15.8",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "15.8",
      target: "14.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "15.8",
      target: "15.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.2",
      target: "1.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.2",
      target: "2.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "2.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.2",
      target: "3.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.2",
      target: "3.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "3.8",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.2",
      target: "4.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.2",
      target: "4.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.2",
      target: "4.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.2",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.2",
      target: "4.7",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.2",
      target: "5.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "6.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "6.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "8.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.2",
      target: "8.6",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "9.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "9.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.2",
      target: "11.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "11.b",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "12.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "12.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "14.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "14.2",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "14.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "15.5",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "15.7",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "15.8",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.2",
      target: "16.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "1.4",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.5",
      target: "2.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "2.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "2.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "2.a",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "3.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "3.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "3.8",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "3.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "4.1",
      key: 0,
    },
    {
      weight: 1.0,
      source: "16.5",
      target: "4.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "4.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "4.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "4.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "4.c",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "5.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "6.1",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "6.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "6.3",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "6.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "7.b",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "8.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "8.6",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "8.9",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "9.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "9.c",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "11.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "11.3",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "11.4",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "11.b",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "12.2",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "12.5",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "14.1",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "14.2",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "14.4",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "15.5",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "15.7",
      key: 0,
    },
    {
      weight: 2.0,
      source: "16.5",
      target: "15.8",
      key: 0,
    },
    {
      weight: 3.0,
      source: "16.5",
      target: "16.2",
      key: 0,
    },
  ],
};
