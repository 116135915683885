import React, { useState, useEffect } from "react";
import Network from "./Network";

const NetworkWrapper = (props) => {
  const {
    networkRef,
    style,
    data,
    selectedLink,
    selectedNodes,
    selectedGoals,
    selectedLinkWeights,
    selectedDirection,
    onClick,
    onLinkClick,
    onWhiteSpaceClick,
    shouldJiggle,
    tooltip,
    onMobile,
    tutorialOpen,
  } = props;
  const [network, setNetwork] = useState(null);

  useEffect(() => {
    if (!networkRef) {
      return;
    }
    if (!network) {
      setNetwork(
        new Network(networkRef.current, {
          data,
          onClick,
          onLinkClick,
          onWhiteSpaceClick,
          tooltip,
          onMobile,
        })
      );
    } else {
      network.update({
        data,
        selectedLink,
        selectedNodes,
        selectedGoals,
        selectedLinkWeights,
        selectedDirection,
        shouldJiggle,
        tutorialOpen,
      });
    }
  }, [
    network,
    networkRef,
    data,
    selectedLink,
    selectedNodes,
    selectedGoals,
    selectedLinkWeights,
    selectedDirection,
    onClick,
    onLinkClick,
    onWhiteSpaceClick,
    shouldJiggle,
    tooltip,
    onMobile,
    tutorialOpen,
  ]);
  return <div style={style} className="network-area" id="network" ref={networkRef}></div>;
};

export default NetworkWrapper;
