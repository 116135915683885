import * as d3 from "d3";

import "./Network.css";

// The standard convention of setting D3 margins
const MARGIN = { TOP: 0, BOTTOM: 0, LEFT: 0, RIGHT: 0 };
const WIDTH = 100 - MARGIN.LEFT - MARGIN.RIGHT;
const HEIGHT = 100 - MARGIN.TOP - MARGIN.BOTTOM;

class Network {
  constructor(element, props) {
    // Create a reference to this class instance
    let vis = this;
    vis.anchorElement = d3.select(element);
    vis.props = props;
    const { data } = vis.props;

    // Create the SVG element
    vis.svg = d3
      .select(element)
      .append("svg")
      .attr("width", WIDTH + MARGIN.LEFT + MARGIN.RIGHT)
      .attr("height", HEIGHT + MARGIN.TOP + MARGIN.BOTTOM);

    vis.g = vis.svg
      .append("g")
      .attr("transform", `translate(${MARGIN.LEFT}, ${MARGIN.TOP})`);

    // Create the scales
    vis.x = d3.scaleLinear().range([0, WIDTH]);
    vis.y = d3.scaleLinear().range([HEIGHT, 0]);
    // Create a node radius range with linear scale from data min and max
    vis.nodeRadius = d3.scaleLinear().range([0, 26]);

    // First render of the chart
    vis.update({ data });
  }

  update(p) {
    // Create a reference to this class instance
    let vis = this;
    vis.props = { ...vis.props, ...p };
    const { data } = vis.props;
    const { props } = vis;

    // Update the scales
    vis.x.domain([0, d3.max(data.nodes, (d) => Number(d.x))]);
    vis.y.domain([0, d3.max(data.nodes, (d) => Number(d.y))]);
    vis.nodeRadius.domain([0, d3.max(data.nodes, (d) => d.priority)]);

    // JOIN
    // EXIT
    // UPDATE
    // ENTER

    // Old network code
    const linkWeightsSet =
      props.selectedLinkWeights && props.selectedLinkWeights.length !== 0;
    const goalsSet = props.selectedGoals && props.selectedGoals.length !== 0;

    const height = parseInt(d3.select("#network").style("height"));
    const width = parseInt(d3.select("#network").style("width"));

    let canvas = vis.anchorElement.select("canvas");
    // Create canvas if not already created
    if (canvas.empty()) {
      canvas = vis.anchorElement.append("canvas");
    }
    canvas
      .attr("width", width)
      .attr("height", height)
      .attr("class", "network-canvas")
      .attr("id", "network-canvas");

    let svg = vis.anchorElement.select("svg");

    // Create svg if not already created
    if (svg.empty()) {
      vis.anchorElement.selectAll("*").remove();
      svg = vis.anchorElement.append("svg");
      svg.append("g");
    }

    const g = svg.select("g");
    g.selectAll("*").remove();

    svg
      .attr("width", width)
      .attr("height", height)
      .attr("class", "network-svg");

    // Create a node radius range with linear scale from data min and max
    const nodeRadiusScale = d3
      .scaleLinear()
      .domain([0, d3.max(data.nodes, (d) => d.priority)])
      .range([0, 26]);

    let linksOfSelectedNode = data.links;

    function hasSelectedNode() {
      // Check if there are node objects in the selectedNodes array
      return props.selectedNodes && props.selectedNodes.length !== 0;
    }

    if (hasSelectedNode()) {
      linksOfSelectedNode = data.links.filter((link) =>
        isLinkOfASelectedNode(link)
      );
    }

    function isSelectedNode(node) {
      if (!hasSelectedNode()) {
        return false;
      }
      return props.selectedNodes.filter(d => d.id === node.id).length > 0;
    }

    function isLinkOfNode(link, node) {
      return (
        ((link.source.id || link.source) === node.id) |
        ((link.target.id || link.target) === node.id)
      );
    }

    // TODO: This is unperformant to calculate on every render wether a link is of a selected node
    // D3 data transform?
    function isLinkOfASelectedNode(link) {
      if (!hasSelectedNode()) {
        return false;
      }
      return !!props.selectedNodes
        .reduce((previousValue, node) => {
          return previousValue || isLinkOfNode(link, node);
        }, false);
    }

    function isSelectedLinkWeight(link) {
      if (!linkWeightsSet) {
        return false;
      }
      return props.selectedLinkWeights.indexOf(link.weight) !== -1;
    }

    function isIncomingLink(link, node) {
      return (link.target.id || link.target) === node.id;
    }

    function isOutgoingLink(link, node) {
      return (link.source.id || link.source) === node.id;
    }

    function isSelectedDirection(link) {
      if (!props.selectedDirection || !hasSelectedNode()) {
        return false;
      }
      if (props.selectedDirection === "incoming") {
        return !!props.selectedNodes.reduce((previousValue, node) => {
          return previousValue || isIncomingLink(link, node);
        }, false);
      }
      if (props.selectedDirection === "outgoing") {
        return !!props.selectedNodes.reduce((previousValue, node) => {
          return previousValue || isOutgoingLink(link, node);
        }, false);
      }
    }

    function isHiddenNode(node) {
      if (!hasSelectedNode()) {
        return false;
      }
      if (isSelectedNode(node)) {
        return false;
      }
      const shownNodes = [];
      linksOfSelectedNode.map((link) => {
        shownNodes.push(link.source);
        shownNodes.push(link.target);
        return true;
      });
      return !shownNodes.includes(node.id);
    }

    function isConnectedBySelectedLinkNode(node) {
      if (!hasSelectedNode()) {
        return false;
      }
      const connectedIDs = [];
      linksOfSelectedNode.map((link) => {
        if (
          linkWeightsSet &&
          props.selectedLinkWeights.indexOf(link.weight) === -1
        ) {
          return false;
        }
        if (props.selectedDirection && !isSelectedDirection(link)) {
          return false;
        }
        connectedIDs.push(link.source);
        connectedIDs.push(link.target);
        return true;
      });
      return connectedIDs.includes(node.id);
    }

    function isSelectedGoal(node) {
      if (!goalsSet) {
        return false;
      }
      return props.selectedGoals.indexOf(node.id.split(".")[0]) !== -1;
    }

    function canSelectLink() {
      return !!hasSelectedNode();
    }

    function isSelectedLink(link) {
      if (!hasSelectedNode()) {
        return false;
      }
      // TODO: replace with link id
      return (
        link.source + link.target ===
        props.selectedLink.source + props.selectedLink.target
      );
    }

    function onOverlayClick(event) {
      props.onWhiteSpaceClick();
    }

    // add an overlay on top of everything to take the mouse events
    g.append("rect")
      .attr("class", "overlay")
      .attr("width", width)
      .attr("height", height)
      .style("fill", "#f00")
      .style("opacity", 0)
      .on("click", onOverlayClick);

    const linkWidth = (d) => {
      return d.weight;
    };

    const shakeNode = () => {
      const transitionTime = 100;
      const centralDistance = 4;
      const randomY = Math.random() * centralDistance;
      const xDist =
        Math.random() > 0.5 ? centralDistance : centralDistance * -1;
      const yDist = Math.random() > 0.5 ? randomY : randomY * -1;
      node
        .transition()
        .delay((d, i) => i * 10 * d.delayFactor)
        .duration(transitionTime)
        .attr(
          "transform",
          (d) => `translate(${d.x + xDist * d.directionFactor}, ${d.y + yDist})`
        )
        .transition()
        .duration(transitionTime * 2)
        .attr(
          "transform",
          (d) => `translate(${d.x - xDist * d.directionFactor}, ${d.y - yDist})`
        )
        .transition()
        .duration(transitionTime)
        .attr("transform", (d) => `translate(${d.x}, ${d.y})`);
    };

    const jiggle = () => {
      node.each((d) => {
        d.delayFactor = Math.random();
        d.directionFactor = Math.random() * 2 - 1;
      });
      shakeNode();
    };

    const drawPath = (d) => {
      const sourceNode = data.nodes.filter((node) => node.id === d.source)[0];
      const targetNode = data.nodes.filter((node) => node.id === d.target)[0];

      // Total difference in x and y from source to target
      const dx = targetNode.x - sourceNode.x;
      const dy = targetNode.y - sourceNode.y;
      // Length of path from center of source node to center of target node
      const dr = Math.sqrt(dx * dx + dy * dy);

      // x and y distances from center to outside edge of target node
      const targetNodeRadius = nodeRadiusScale(targetNode.priority);
      const offsetX = (dx * targetNodeRadius) / dr;
      const offsetY = (dy * targetNodeRadius) / dr;

      const pathEndX = targetNode.x - offsetX;
      const pathEndY = targetNode.y - offsetY;

      return `M${sourceNode.x},${sourceNode.y}A${dr * 1.5},${
        dr * 1.5
      } 0 0,1 ${pathEndX},${pathEndY}`;
    };

    const draw = (useCanvas, selector) => {
      if (useCanvas) {
        // Remove svg links
        path.attr("d", function (d) {
          return null;
        });

        // Draw links on canvas
        const context = d3.select("canvas").node().getContext("2d");
        context.clearRect(0, 0, width, height);
        context.lineWidth = 1;
        context.strokeStyle = "lightgray";
        path.each((d, i, nodes) => {
          const sourceNode = data.nodes.filter(
            (node) => node.id === d.source
          )[0];
          const targetNode = data.nodes.filter(
            (node) => node.id === d.target
          )[0];
          context.beginPath();
          context.moveTo(sourceNode.x, sourceNode.y);
          context.lineTo(targetNode.x, targetNode.y);
          context.stroke();
        });
      } else {
        // Remove canvas links
        const context = d3.select("canvas").node().getContext("2d");
        context.clearRect(0, 0, width, height);
        path
          .filter((d) => !selector || selector(d))
          .attr("d", function (d) {
            return drawPath(d);
          });
        path2
          .filter((d) => !selector || selector(d))
          .attr("d", function (d) {
            return drawPath(d);
          });
      }

      node.attr("transform", (d) => `translate(${d.x}, ${d.y})`);
    };

    var defs = svg.append("svg:defs");

    function marker(d) {
      defs
        .append("svg:marker")
        .attr("id", d.color.replace("#", ""))
        .attr("viewBox", "0 -5 10 10")
        .attr("refX", 10)
        .attr("refY", 0)
        .attr("markerWidth", 9)
        .attr("markerHeight", 9)
        .attr("orient", "auto")
        .attr("markerUnits", "userSpaceOnUse")
        .append("svg:path")
        .attr("d", "M0,-5L10,0L0,5")
        .style("fill", d.color);
      return "url(" + d.color + ")";
    }

    defs
      .append("svg:marker")
      .attr("id", "grey-marker")
      .attr("viewBox", "0 -5 10 10")
      .attr("refX", 10)
      .attr("refY", 0)
      .attr("markerWidth", 9)
      .attr("markerHeight", 9)
      .attr("orient", "auto")
      .attr("markerUnits", "userSpaceOnUse")
      .append("svg:path")
      .attr("d", "M0,-5L10,0L0,5")
      .style("fill", "lightgray");

    // The links between the nodes
    const path = g
      .append("g")
      .selectAll(".link")
      .data(linksOfSelectedNode)
      .enter()
      .append("path")
      .attr("class", "link")
      .attr("stroke-width", (d) => linkWidth(d))
      .style("fill", "none")
      .attr("cursor", function (d) {
        return canSelectLink() ? "pointer" : null;
      })
      .attr("marker-end", function (d) {
        if (!hasSelectedNode()) {
          return "url(#grey-marker)";
        }
        // If a link weight and link direction is highlighted only color the according links
        if (linkWeightsSet && props.selectedDirection) {
          return isSelectedLinkWeight(d) && isSelectedDirection(d)
            ? marker(d)
            : "url(#grey-marker)";
        }
        if (linkWeightsSet) {
          return isSelectedLinkWeight(d) ? marker(d) : "url(#grey-marker)";
        }
        if (props.selectedDirection) {
          return isSelectedDirection(d) ? marker(d) : "url(#grey-marker)";
        }
        return isLinkOfASelectedNode(d) ? marker(d) : "url(#grey-marker)";
      })
      .attr("stroke", function (d) {
        // If there is no node selected, all links are grey
        if (!hasSelectedNode()) {
          return "lightgray";
        }
        // If a link weight and link direction is highlighted only color the according links
        if (linkWeightsSet && props.selectedDirection) {
          return isSelectedLinkWeight(d) && isSelectedDirection(d)
            ? d.color
            : "lightgray";
        }
        if (linkWeightsSet) {
          return isSelectedLinkWeight(d) ? d.color : "lightgray";
        }
        if (props.selectedDirection) {
          return isSelectedDirection(d) ? d.color : "lightgray";
        }
        // Only color links that are connected to the highlighted node
        return isLinkOfASelectedNode(d) ? d.color : "lightgray";
      })
      .attr("opacity", function (d) {
        if (!hasSelectedNode()) {
          return 0.3;
        }
        // If a link is selected color only this link, all other links are grey
        if (props.selectedLink) {
          return isSelectedLink(d) ? 1 : 0.3;
        }

        // If a link weight and link direction is highlighted only color the according links
        if (linkWeightsSet && props.selectedDirection) {
          return isSelectedLinkWeight(d) && isSelectedDirection(d) ? 1 : 0.3;
        }
        if (linkWeightsSet) {
          return isSelectedLinkWeight(d) ? 1 : 0.3;
        }
        if (props.selectedDirection) {
          return isSelectedDirection(d) ? 1 : 0.3;
        }
        return isLinkOfASelectedNode(d) ? 1 : 0.3;
      })
      .on("click", function (event) {
        if (event.defaultPrevented) return; // dragged
        if (!canSelectLink()) return;
        // Get this node's data
        const datum = d3.select(this).datum();
        props.onLinkClick(datum);
      });

    // A copy of the paths to increase the stroke width
    const path2 = g
      .append("g")
      .selectAll(".link2")
      .data(linksOfSelectedNode)
      .enter()
      .append("path")
      .attr("class", "link2")
      .attr("stroke-width", 4)
      .style("fill", "none")
      .attr("cursor", function (d) {
        return canSelectLink() ? "pointer" : null;
      })
      .attr("stroke", "transparent")
      .on("click", function (event) {
        if (event.defaultPrevented) return; // dragged
        if (!canSelectLink()) return;
        // Get this node's data
        const datum = d3.select(this).datum();
        props.onLinkClick(datum);
      });

    const node = g
      .selectAll(".node")
      .data(data.nodes, function (d) {
        return d.id;
      })
      .enter()
      // If a node is selected show only the selected node plus connections
      .filter((d) => !isHiddenNode(d))
      .append("g")
      .attr("class", "node")
      .attr("cursor", "pointer")
      .on("mousemove", function (event) {
        if (props.onMobile || props.tutorialOpen) {
          return;
        }
        // Get this node's data
        const datum = d3.select(this).datum();
        // Add the tooltip to the svg container
        vis.anchorElement
          .style("position", "relative")
          .append("div")
          .attr("class", "tooltip")
          .style("opacity", 0.9)
          .html(props.tooltip(datum))
          .style("left", `${datum.x + nodeRadiusScale(datum.priority)}px`)
          .style("bottom", `${height - datum.y}px`);
      })
      .on("mouseleave", function (event) {
        // Remove all tooltips
        d3.selectAll(".tooltip").remove();
      })
      .on("mousedown", function (event) {
        const d = d3.select(this);
        d.attr("cursor", "move");
      })
      .on("pointerup", function (event) {
        const d = d3.select(this);
        d.attr("cursor", "pointer");
      })
      .on("click", function (event) {
        if (event.defaultPrevented) return; // dragged
        // Get this node's data
        const datum = d3.select(this).datum();
        props.onClick(datum);
      })
      .call((g) =>
        g
          .append("circle")
          .attr("stroke", "#fff")
          .attr("stroke-width", 1.5)
          .attr("r", (d) => nodeRadiusScale(d.priority))
          .attr("fill", function (d) {
            if (goalsSet) {
              return isSelectedGoal(d) ? d.color : "lightgray";
            }
            if (!hasSelectedNode()) {
              return d.color;
            }
            return isConnectedBySelectedLinkNode(d) || isSelectedNode(d)
              ? d.color
              : "lightgray";
          })
      )
      .call((g) =>
        g
          .append("text")
          .attr("text-anchor", "middle")
          .attr("font-size", (d) => nodeRadiusScale(d.priority) * 0.8)
          .attr("fill", "#fff")
          .attr("dy", ".35em")
          .text((d) => d.id)
      )
      .call(
        d3
          .drag()
          .on("drag", (event, d) => {
            // Remove all tooltips
            d3.selectAll(".tooltip").remove();
            // Restrict dragging to the svg rectangle
            const r = nodeRadiusScale(d.priority);
            let x = event.x;
            if (x + r > width) {
              x = width - r;
            } else if (event.x - r < 0) {
              x = 0 + r;
            }
            let y = event.y;
            if (y + r > height) {
              y = height - r;
            } else if (event.y - r < 0) {
              y = 0 + r;
            }
            // Update node position
            d.x = x;
            d.y = y;
          })
          .on("start.update end.update", (event, d) =>
            draw(!hasSelectedNode(), (link) => isLinkOfNode(link, d))
          )
          .on("drag.update", (event, d) =>
            draw(!hasSelectedNode(), (link) => isLinkOfNode(link, d))
          )
      );

    // Add a circle to highlight nodes of the selected goals
    node
      .filter((d) => isSelectedGoal(d) || isSelectedNode(d))
      .call((g) =>
        g
          .append("circle")
          .attr("class", "highlight-circle")
          .attr("r", (d) => nodeRadiusScale(d.priority) + 3)
          .attr("fill", "none")
          .attr("stroke", (d) => d.color)
          .attr("stroke-width", 1.5)
      );

    draw(!hasSelectedNode());
    if (props.shouldJiggle) {
      jiggle();
    }
  }
}

export default Network;
