export const indicatorInfo = {
  "1.4.1": {
    indicatorES:
      "Proporción de la población que vive en hogares con acceso a los servicios básicos",
    indicatorEN: "Proportion of population living in households with access to basic services"
  },
  "1.4.2": {
    indicatorES:
      "Proporción del total de la población adulta con derechos seguros de tenencia de la tierra: a) que posee documentación reconocida legalmente al respecto y b) considera seguros sus derechos, desglosada por sexo y tipo de tenencia",
    indicatorEN:
      "Proportion of total adult population with secure tenure rights to land, (a) with legally recognized documentation, and (b) who perceive their rights to land as secure, by sex and type of tenure"
  },
  "2.2.1": {
    indicatorES:
      "Prevalencia del retraso del crecimiento (estatura para la edad, desviación típica < -2 de la mediana de los patrones de crecimiento infantil de la Organización Mundial de la Salud (OMS)) entre los niños menores de 5 años",
    indicatorEN:
      "Prevalence of stunting (height for age <-2 standard deviation from the median of the World Health Organization (WHO) Child Growth Standards) among children under 5 years of age"
  },
  "2.2.2": {
    indicatorES:
      "Prevalencia de la malnutrición (peso para la estatura, desviación típica > +2 o < -2 de la mediana de los patrones de crecimiento infantil de la OMS) entre los niños menores de 5 años, desglosada por tipo (emaciación y sobrepeso)",
    indicatorEN:
      "Prevalence of malnutrition (weight for height >+2 or <-2 standard deviation from the median of the WHO Child Growth Standards) among children under 5 years of age, by type (wasting and overweight)"
  },
  "2.2.3": {
    indicatorES:
      "Prevalencia de la anemia en las mujeres de entre 15 y 49 años, desglosada por embarazo (porcentaje)",
    indicatorEN:
      "Prevalence of anemia in women aged 15 to 49 years, by pregnancy status (percentage)"
  },
  "2.3.1": {
    indicatorES:
      "Volumen de producción por unidad de trabajo desglosado por tamaño y tipo de explotación (agropecuaria / ganadera / pesquero)",
    indicatorEN:
      "Volume of production per labor unit by classes of farming/pastoral/fishing enterprise size"
  },
  "2.3.2": {
    indicatorES:
      "Media de ingresos de los productores de alimentos en pequeña escala, desglosada por sexo",
    indicatorEN: "Average income of small-scale food producers, by sex"
  },
  "2.4.1": {
    indicatorES:
      "Proporción de la superficie agrícola en que se practica una agricultura productiva y sostenible",
    indicatorEN: "Proportion of agricultural area under productive and sustainable agriculture"
  },
  "2.a.1": {
    indicatorES: "Índice de orientación agrícola para el gasto público",
    indicatorEN: "The agriculture orientation index for government expenditures"
  },
  "2.a.2": {
    indicatorES:
      "Total de recursos asignados para proyectos de inversión destinados al sector agrícola",
    indicatorEN: "Total resources allocated for investment projects in the agricultural sector"
  },
  "3.5.1": {
    indicatorES:
      "Cobertura de los tratamientos (farmacológicos y psicosociales y servicios de rehabilitación y postratamiento) de trastornos por abuso de sustancias adictivas",
    indicatorEN:
      "Coverage of treatment interventions (pharmacological, psychosocial and rehabilitation and aftercare services) for substance use disorders"
  },
  "3.5.2": {
    indicatorES:
      "Consumo de alcohol per cápita (a partir de los 15 años de edad) durante un año civil en litros de alcohol puro",
    indicatorEN:
      "Alcohol per capita consumption (aged 15 years and older) within a calendar year in litres of pure alcohol"
  },
  "3.7.1": {
    indicatorES:
      "Proporción de mujeres en edad de procrear (entre 15 y 49 años) que cubren sus necesidades de planificación familiar con métodos modernos",
    indicatorEN:
      "Proportion of women of reproductive age (aged 15-49 years) who have their need for family planning satisfied with modern methods"
  },
  "3.7.2": {
    indicatorES:
      "Tasa de fecundidad de las adolescentes (entre 10 y 14 años y entre 15 y 19 años) por cada 1.000 mujeres de ese grupo de edad",
    indicatorEN:
      "Adolescent birth rate (aged 10-14 years; aged 15-19 years) per 1,000 women in that age group"
  },
  "3.8.1": {
    indicatorES: "Cobertura de los servicios de salud esenciales",
    indicatorEN: "Coverage of essential health services"
  },
  "3.8.2": {
    indicatorES:
      "Proporción de la población con grandes gastos sanitarios por hogar como porcentaje del total de gastos o ingresos de los hogares",
    indicatorEN:
      "Proportion of population with large household expenditures on health as a share of total household expenditure or income"
  },
  "3.c.1": {
    indicatorES:
      "Densidad y distribución del personal sanitario y proporción del personal sanitario en posiciones permanentes",
    indicatorEN:
      "Health worker density and distribution, and proportion of health workers in permanent positions"
  },
  "4.1.1": {
    indicatorES:
      "Proporción de niños, niñas y adolescentes que, a) en los cursos segundo y tercero, b) al final de la enseñanza primaria y c) al final de la enseñanza secundaria inferior, han alcanzado al menos un nivel mínimo de competencia en i) lectura y ii) matemáticas, desglosada por sexo",
    indicatorEN:
      "Proportion of children and young people (a) in grades 2/3; (b) at the end of primary; and (c) at the end of lower secondary achieving at least a minimum proficiency level in (i) reading and (ii) mathematics, by sex"
  },
  "4.1.2": {
    indicatorES:
      "Índice de finalización (enseñanza primaria, primer ciclo de enseñanza secundaria y segundo ciclo de enseñanza secundaria)",
    indicatorEN:
      "Completion rate (primary education, lower secondary education, upper secondary education)"
  },
  "4.2.1": {
    indicatorES:
      "Proporción de niños de 24 a 59 meses cuyo desarrollo es adecuado en cuanto a la salud, el aprendizaje y el bienestar psicosocial, desglosada por sexo",
    indicatorEN:
      "Proportion of children aged 24-59 months who are developmentally on track in health, learning and psychosocial well-being, by sex"
  },
  "4.2.2": {
    indicatorES:
      "Tasa de participación en el aprendizaje organizado (un año antes de la edad oficial de ingreso en la enseñanza primaria), desglosada por sexo",
    indicatorEN:
      "Participation rate in organized learning (one year before the official primary entry age), by sex"
  },
  "4.3.1": {
    indicatorES:
      "Tasa de participación de los jóvenes y adultos en la enseñanza y formación académica y no académica en los últimos 12 meses, desglosada por sexo",
    indicatorEN:
      "Participation rate of youth and adults in formal and non-formal education and training in the previous 12 months, by sex"
  },
  "4.4.1": {
    indicatorES:
      "Proporción de jóvenes y adultos residentes con competencias en tecnología de la información y las comunicaciones (TIC), desglosada por tipo de competencia técnica",
    indicatorEN:
      "Proportion of youth and resident adults with information and communications technology (ICT) skills, by type of skills"
  },
  "4.7.1": {
    indicatorES:
      "Grado en que i) la educación para la ciudadanía mundial y ii) la educación para el desarrollo sostenible se incorporan en a) las políticas nacionales de educación, b) los planes de estudio, c) la formación del profesorado y d) la evaluación de los estudiantes",
    indicatorEN:
      "Extent to which (i) global citizenship education and (ii) education for sustainable development are mainstreamed in (a) national education policies; (b) curricula; (c) teacher education; and (d) student assessment"
  },
  "4.c.1": {
    indicatorES:
      "Proporción de docentes con las calificaciones mínimas requeridas, desglosada por nivel educativo",
    indicatorEN:
      "Proportion of teachers with the minimum required qualifications, by education level"
  },
  "4.c.2": {
    indicatorES: "Proporción de escuelas que enseñan el currículo contextualizado ",
    indicatorEN: "Proportion of schools teaching the contextualized curriculum"
  },
  "5.2.1": {
    indicatorES:
      "Proporción de mujeres y niñas a partir de 15 años de edad que han sufrido violencia física, sexual o psicológica a manos de su actual o anterior pareja en los últimos 12 meses, desglosada por forma de violencia y edad",
    indicatorEN:
      "Proportion of ever-partnered women and girls aged 15 years and older subjected to physical, sexual or psychological violence by a current or former intimate partner in the previous 12 months, by form of violence and by age"
  },
  "5.2.2": {
    indicatorES:
      "Proporción de mujeres y niñas a partir de 15 años de edad que han sufrido violencia sexual a manos de personas que no eran su pareja en los últimos 12 meses, desglosada por edad y isla",
    indicatorEN:
      "Proportion of women and girls aged 15 years and older subjected to sexual violence by persons other than an intimate partner in the previous 12 months, by age and island"
  },
  "6.1.1": {
    indicatorES: "Proporción de la población con acceso confiable a agua potable sin riesgos",
    indicatorEN: "Proportion of population with reliable access to safe drinking water "
  },
  "6.2.1": {
    indicatorES:
      "Proporción de la población que utiliza: a) servicios de saneamiento gestionados sin riesgos (donde las aguas residuales se eliminan y tratan de manera adecuada)",
    indicatorEN:
      "Proportion of population using (a) safely managed sanitation services (i.e. where sewage is safely removed and treated)"
  },
  "6.3.1": {
    indicatorES:
      "Proporción de los flujos de aguas residuales domésticas e industriales tratados de manera adecuada",
    indicatorEN: "Proportion of domestic and industrial wastewater flows safely treated"
  },
  "6.3.2": {
    indicatorES: "Proporción de masas de agua de buena calidad",
    indicatorEN: "Proportion of bodies of water with good ambient water quality"
  },
  "6.4.1": {
    indicatorES: "Cambio en el uso eficiente de los recursos hídricos con el paso del tiempo",
    indicatorEN: "Change in water-use efficiency over time"
  },
  "6.4.2": {
    indicatorES:
      "Nivel de estrés hídrico: extracción de agua dulce en proporción a los recursos de agua dulce disponibles",
    indicatorEN:
      "Level of water stress: freshwater withdrawal as a proportion of available freshwater resources"
  },
  "7.b.1": {
    indicatorES:
      "Capacidad instalada de generación de energía renovable (expresada en vatios per cápita)",
    indicatorEN: "Installed renewable energy-generating capacity (in watts per capita)"
  },
  "8.2.1": {
    indicatorES: "Tasa de crecimiento anual del PIB real por residente empleada",
    indicatorEN: "Annual growth rate of real GDP per employed resident "
  },
  "8.2.2": {
    indicatorES: "Proporción de residentes empleados en campos innovadores y tecnológicos ",
    indicatorEN: "Proportion of employed residents in innovative and technological fields"
  },
  "8.6.1": {
    indicatorES:
      "Proporción de jóvenes (entre 15 y 24 años) que no cursan estudios, no están empleados ni reciben capacitación",
    indicatorEN: "Proportion of youth (aged 15-24 years) not in education, employment or training"
  },
  "8.9.1": {
    indicatorES:
      "PIB generado directamente por el turismo en proporción al PIB total y a la tasa de crecimiento",
    indicatorEN: "Tourism direct GDP as a proportion of total GDP and in growth rate"
  },
  "9.5.1": {
    indicatorES: "Gastos en investigación y desarrollo en proporción al PIB",
    indicatorEN: "Research and development expenditure as a proportion of GDP"
  },
  "9.5.2": {
    indicatorES: "Proporción de residentes empleados en la investigación ",
    indicatorEN: "Proportion of employed residents in research"
  },
  "9.c.1": {
    indicatorES:
      "Proporción de la población con cobertura de i) red móvil, y ii) internet de velocidad razonable",
    indicatorEN:
      "Proportion of population covered by a i) mobile network, and ii) by internet of reasonable speed"
  },
  "11.2.1": {
    indicatorES:
      "Proporción de la población que tiene fácil acceso al transporte público, desglosada por sexo, edad y personas con discapacidad",
    indicatorEN:
      "Proportion of population that has convenient access to public transport, by sex, age and persons with disabilities"
  },
  "11.3.1": {
    indicatorES:
      "Relación entre la tasa de consumo de tierras y la tasa de crecimiento de la población",
    indicatorEN: "Ratio of land consumption rate to population growth rate"
  },
  "11.3.2": {
    indicatorES:
      "Proporción de ciudades que cuentan con una estructura de participación directa de la sociedad civil en la planificación y la gestión urbanas y funcionan con regularidad y democráticamente",
    indicatorEN:
      "Proportion of cities with a direct participation structure of civil society in urban planning and management that operate regularly and democratically"
  },
  "11.4.1": {
    indicatorES:
      "Total de gastos per cápita destinados a la preservación, protección y conservación de todo el patrimonio cultural y natural, desglosado por fuente de financiación (pública y privada), tipo de patrimonio (cultural y natural) y nivel de gobierno (nacional, regional y local/municipal)",
    indicatorEN:
      "Total per capita expenditure on the preservation, protection and conservation of all cultural and natural heritage, by source of funding (public, private), type of heritage (cultural, natural) and level of government (national, regional, and local/municipal)"
  },
  "11.b.1": {
    indicatorES:
      "Proporción de municipios/parroquias que adoptan y aplican estrategias locales de reducción del riesgo de desastres en consonancia con las estrategias nacionales de reducción del riesgo de desastres",
    indicatorEN:
      "Proportion of municipalities/parishes that adopt and implement local disaster risk reduction strategies in line with national disaster risk reduction strategies"
  },
  "12.2.1": {
    indicatorES:
      "Huella material en términos absolutos, huella material per cápita y huella material por PIB",
    indicatorEN: "Material footprint, material footprint per capita, and material footprint per GDP"
  },
  "12.2.2": {
    indicatorES:
      "Consumo material local en términos absolutos, consumo material local per cápita y consumo material local por PIB",
    indicatorEN:
      "Local material consumption, local material consumption per capita, and local material consumption per GDP"
  },
  "12.5.1": {
    indicatorES: "Tasa nacional de reciclado, en toneladas de material reciclado",
    indicatorEN: "National recycling rate, tons of material recycled"
  },
  "14.1.1": {
    indicatorES: "a) Índice de eutrofización costera; y b) densidad de detritos plásticos",
    indicatorEN: "(a) Index of coastal eutrophication; and (b) plastic debris density"
  },
  "14.2.1": {
    indicatorES:
      "Proporción de acciones de gestión de las áreas marinas que utilizan enfoques basados en los ecosistemas",
    indicatorEN: "Proportion of marine area management actions that use ecosystem-based approaches "
  },
  "14.4.1": {
    indicatorES: "Proporción de poblaciones de peces cuyos niveles son biológicamente sostenibles",
    indicatorEN: "Proportion of fish stocks within biologically sustainable levels"
  },
  "15.5.1": {
    indicatorES: "Índice de la Lista Roja/tendencias de la población ",
    indicatorEN: "Red List Index/population trends"
  },
  "15.7.1": {
    indicatorES: "Numero de organismos individuales cazados furtivamente o traficados",
    indicatorEN: "Number of individual organisms poached or trafficked "
  },
  "15.8.1": {
    indicatorES:
      "Proporción de especies establecidas que son introducidas o invasores, en los ambientes terrestres y marinos, por taxones",
    indicatorEN:
      "Proportion of established species that are introduced or invasive, in the terrestrial and marine environments, by taxa "
  },
  "16.2.1": {
    indicatorES:
      "Proporción de niños de entre 1 y 17 años que han sufrido algún castigo físico o agresión psicológica a manos de sus cuidadores en el último mes",
    indicatorEN:
      "Proportion of children aged 1-17 years who experienced any physical punishment and/or psychological aggression by caregivers in the past month"
  },
  "16.2.2": {
    indicatorES:
      "Número de víctimas de la trata de personas por cada 100.000 habitantes, desglosado por sexo, edad y tipo de explotación",
    indicatorEN:
      "Number of victims of human trafficking per 100,000 population, by sex, age and form of exploitation"
  },
  "16.2.3": {
    indicatorES:
      "Proporción de mujeres y hombres jóvenes de entre 18 y 29 años que sufrieron violencia sexual antes de cumplir los 18 años",
    indicatorEN:
      "Proportion of young women and men aged 18-29 years who experienced sexual violence by age 18"
  },
  "16.5.1": {
    indicatorES:
      "Proporción de personas que han tenido al menos un contacto con un funcionario público y que han pagado un soborno a un funcionario público, o a las que un funcionario público les ha pedido un soborno, durante los últimos 12 meses",
    indicatorEN:
      "Proportion of persons who had at least one contact with a public official and who paid a bribe to a public official, or were asked for a bribe by those public officials, during the previous 12 months"
  },
  "16.5.2": {
    indicatorES:
      "Proporción de negocios que han tenido al menos un contacto con un funcionario público y que han pagado un soborno a un funcionario público, o a los que un funcionario público les ha pedido un soborno, durante los últimos 12 meses",
    indicatorEN:
      "Proportion of businesses that had at least one contract with a public official and that paid a bribe to a public official, or were asked for a bribe by those public officials during the previous 12 months"
  }
};
